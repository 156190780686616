import TopNavbar from "components/navbar/top_navbar";
import BottomNav from "components/navbar/bottom_navbar";
import img1 from "../../assets/drawables/otherimg/img1.png";
import img2 from "../../assets/drawables/otherimg/img2.png";
import img3 from "../../assets/drawables/otherimg/img3.png";
import forexvid from "../../assets/video/forex.mp4";
import search_icon from "../../assets/drawables/search.svg";
import "./style.scss";

const newsList = [
  {
    img: img2,
    video: true,
    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
  {
    img: img2,

    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
  {
    img: img3,

    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
  {
    img: img2,

    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
  {
    img: img2,
    video: true,
    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
  {
    img: img1,
    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
  {
    img: img2,
    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
  {
    img: img2,
    video: true,
    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
  {
    img: img2,
    title: "Opening a Dollar Account",
    last: "Bank  ·  3 mins",
  },
];

export default function HowItWorks() {

  return (
    <div>
      <TopNavbar />
      <BottomNav />
      <div className="otherpage_text">
        <h1 className="bolden">
          Lorem ipsum dolor sit amet,
          <br /> consectetur adipiscing elit
        </h1>

        <form className="otherpage_search">
          <img src={search_icon} alt="search"></img>
          <input placeholder="search" type="text" />
        </form>

        <div className="otherpage_btn">
          <span>All</span>
          <span>Investments</span>
          <span>Money Transfer</span>
          <span>Bank</span>
        </div>

        <div className="cover_grid_other">
          {newsList.map((item, i) => {
            return (
              <div className="each_other_card" key={i}>
                <div className="top_side">
                  {item.video ? (
                    <video
                      width="400"
                      controls
                      src={forexvid}
                    />
                  ) : (
                    <img src={item.img} alt="" />
                  )}
                </div>
                <div className="btm_side">
                  <p className="title">{item.title}</p>
                  <p className="last">{item.last}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
