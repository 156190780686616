import React, { useState } from "react";
import ConvertCurrencyDropDown from "components/InputFields/convertCurrencyDropDown";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { Currency } from "lib/api/apiTypes";
import { useNavigate } from "react-router-dom";

type SendMoneyBoxProps = {
	currencies: Currency[],
}
/**
 * @todo Extract to reusable component and use across app
 */
export function SendMoneyBox({ currencies }: SendMoneyBoxProps) {
	const navigate = useNavigate();

	const [sendingCurrency, setSendingCurrency] = useState(currencies[0].code);
	const [receivingCurrency, setReceivingCurrency] = useState(currencies[1].code);

	return (
		<div className="send_money">
			<p className="title">Send Money</p>
			<div className="changeBox">
				<div className="top hard">
					<div className="left">
						<p className="changeText">Tender</p>
						<input className="number" defaultValue={20} />
					</div>
					<div className="right">
						<ConvertCurrencyDropDown
							currencies={currencies}
							value={sendingCurrency}
							onChange={setSendingCurrency}
						/>
					</div>
				</div>
				<div className="top">
					<div className="left">
						<p className="changeText">Buy</p>
						<input className="number" defaultValue={20} />
					</div>
					<div className="right">
						<ConvertCurrencyDropDown
							currencies={currencies}
							value={receivingCurrency}
							onChange={setReceivingCurrency}
						/>
					</div>
				</div>
			</div>
			<SubmitBtn
				className='green'
				btnText='Send Money'
				onClick={() => navigate('/send')}
			/>{" "}
		</div>
	);
}
