import React from "react";
import userblue from "assets/drawables/userblue.svg";
import "./style.scss";
import { ID, TransferRecipient } from "lib/api/apiTypes";

const recipientTypeNames = {
  'external-bank-account': 'Bank Account',
  user: 'Wallet',
}

type EachReceipientCardProps = {
  item: TransferRecipient,
  onClick: (id: ID) => void,
}
const EachReceipientCard = ({ item, onClick }: EachReceipientCardProps) => {
  return (
    <div onClick={() => onClick(item.id)} className='pd_recipient_card'>
      <div className="cover_img">
        <img src={userblue} alt="bank" />
      </div>
      <div className="text_side">
        <p className="top">{item.name}</p>
        <div className="dot"></div>
        <p className="bottom">{recipientTypeNames[item.type]}</p>
      </div>
    </div>
  );
};

export default EachReceipientCard;
