import NavCard from "../Market/NavCard";
import cancel from "../../../assets/drawables/cancel.svg";
import "./style.scss";
import { useState } from "react";
import CurrencyDrop from "components/currencyDrop";
import nig from "../../../assets/drawables/nigeria.svg";
import convert from "../../../assets/drawables/convert2.svg";
import ech from "../../../assets/drawables/ech.svg";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import indicator from "../../../assets/drawables/indicate.svg";
import SmallModal from "components/ModalPopUp/smallModal";
import ProcessingModal from "components/ProcessingModal";
import ConvertFlagDropDown from "components/InputFields/convertFlagDropDown";
const Convert = () => {
  const [tender, setTender] = useState("");
  const [buy, setBuy] = useState("");
  const [openModal, setOpenModal] = useState(false);
  console.log(buy, tender);
  return (
    <div className="pd_convert_page">
      <NavCard dnone={false} />
      <div className="main_aspect">
        <div className="currency_top">
          <p>Convert</p>
          <img src={cancel} alt="cancel" />
        </div>

        <div className="card_convert">
          <p className="enter">Enter the amount you want to convert</p>
          <p className="available">Available $ 2,219.09</p>
          <div className="cover_tender">
            <div className="changeBox">
              <div className="cover_all_top">
                <div className="conv">
                  <img src={convert} alt="convert" />
                </div>
                <div className="top">
                  <div className="left">
                    <p className="changeText">Tender</p>
                    <p className="number">20</p>
                  </div>
                  <div className="right">
                    <ConvertFlagDropDown handleCurrency={setTender} />
                    {/* <CurrencyDrop
                    handleCurrency={(val) => {
                      setTender(val);
                    }}
                    defaultCurrency={{ flag: nig, code: "NGN" }}
                  /> */}
                  </div>
                </div>
                <div className="top">
                  <div className="left">
                    <p className="changeText">Buy</p>
                    <p className="number">20</p>
                  </div>
                  <div className="right">
                    <ConvertFlagDropDown handleCurrency={setBuy} />
                    {/* <CurrencyDrop
                    handleCurrency={(val) => {
                      setBuy(val);
                    }}
                    defaultCurrency={{ flag: nig, code: "NGN" }}
                  /> */}
                  </div>
                </div>
              </div>
              <div className="ech_cover">
                <img src={ech} alt="ech" />
                <p>Exchange Rate</p>
              </div>

              <SubmitBtn
                onClick={() => {
                  setOpenModal(true);
                }}
                btnText={"Convert"}
              />
            </div>
          </div>
        </div>
        <div className="ech_cover">
          <img src={indicator} alt="indicator" />
          <p>Fund will be directly invested in currency you select</p>
        </div>
      </div>
      <SmallModal closeModal={() => setOpenModal(false)} openModal={openModal}>
        <ProcessingModal
          desc={
            "You have successfully converted $2,000 to GBP. Your GBP portfolio has been credited "
          }
          title={"Success"}
          loading={true}
          loadText={"Please wait while we process your transaction"}
        />
      </SmallModal>
    </div>
  );
};

export default Convert;
