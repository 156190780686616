import { ID } from "lib/api/apiTypes";
import "./style.scss";

type EachAccountCardProps = {
  id: ID,
  amount: string | number,
  flag: string,
  currency: string,
}

const EachAccountCard = ({ id, flag, currency, amount }: EachAccountCardProps) => {
  return (
    <div className="pd_each_account_card">
      <div className="top">
        <p className="currency">{currency}</p>
        <img src={flag} alt="" className="flag" />
      </div>
      <p className="amount">{amount}</p>
    </div>
  );
};

export default EachAccountCard;
