import "./style.scss";
import usa from "../../assets/drawables/usa.svg";
import greenArr from "../../assets/drawables/greenArrow.svg";
import arrow2 from "../../assets/drawables/arrow2.svg";
import nig from "../../assets/drawables/nigeria.svg";
import redArr from "../../assets/drawables/redArrow.svg";
import cad from "../../assets/drawables/england.svg";
import { useState } from "react";
const FootNote = () => {
  const [active, setActive] = useState("risers");
  const handleActive = (type) => {
    setActive(type);
  };
  return (
    <div className="footNote">
      <p id="footNote_title">Market Highlights</p>
      <p className="sub-title">
        Get insight on how world currencies are performing
      </p>

      <div className="viewFlex">
        <div className="btnDiv">
          <button
            onClick={() => {
              handleActive("risers");
            }}
            className={`${active === "risers" && "yellow"}`}
          >
            Top Risers
          </button>
          <button
            onClick={() => {
              handleActive("fallers");
            }}
            className={`${active === "fallers" && "yellow"}`}
          >
            Top Fallers
          </button>
          <button
            onClick={() => {
              handleActive("leaders");
            }}
            className={`${active === "leaders" && "yellow"}`}
          >
            Volume Leaders
          </button>
        </div>
        <a href="/">View More</a>
      </div>
      <div className="boxShow">
        <div className="eachBox">
          <div className="flag">
            <img src={usa} alt="usa" />
            <p>USD. Dollar Backed Security</p>
          </div>
          <div className="rate">
            <span className="cur">USD</span>
            <span className="money">219.90</span>
          </div>
          <div className="arrow">
            <img src={greenArr} alt="arrow" />
            <p className="green">1.4%</p>
          </div>
          <div className="track">
            <p>Lasted updated January 9</p>
            <a href="/">
              {" "}
              Track <img src={arrow2} alt="arrow" />{" "}
            </a>
          </div>
        </div>
        <div className="eachBox">
          <div className="flag">
            <img src={nig} alt="usa" />
            <p>NGN. Naira Backed Security</p>
          </div>
          <div className="rate">
            <span className="cur">NGN</span>
            <span className="money">219.90</span>
          </div>
          <div className="arrow">
            <img src={redArr} alt="arrow" />
            <p className="red">1.4%</p>
          </div>
          <div className="track">
            <p>Lasted updated January 9</p>
            <a href="/">
              {" "}
              Track <img src={arrow2} alt="arrow" />{" "}
            </a>
          </div>
        </div>
        <div className="eachBox">
          <div className="flag">
            <img src={cad} alt="usa" />
            <p>CAD. Dollar Backed Security</p>
          </div>
          <div className="rate">
            <span className="cur">CAD</span>
            <span className="money">219.90</span>
          </div>
          <div className="arrow">
            <img src={greenArr} alt="arrow" />
            <p className="green">1.4%</p>
          </div>
          <div className="track">
            <p>Lasted updated January 9</p>
            <a href="/">
              {" "}
              Track <img src={arrow2} alt="arrow" />{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FootNote;
