import { WalletTransactionMinimal } from "lib/api/apiTypes";
import eng from "assets/drawables/england.svg";
import nig from "assets/drawables/nigeria.svg";
import arrow from "assets/drawables/arrow.svg";
import { BackLink } from "./BackLink";
import { useAppData } from "default/AppDataProvider";
import format from "date-fns/format";
import { useParams } from "react-router-dom";
import { useWalletTransactionQuery } from "pages/Dashboard/BankICE/queries";
import { ComponentLoader } from "components/ComponentLoader";

type TransactionDetailProps = {
  tranx: WalletTransactionMinimal,
}
function TransactionDetailView({ tranx }: TransactionDetailProps) {
  const { appInfo } = useAppData();
  const tranxCurrency = appInfo.supportedCurrencies.find((item) => item.code === tranx.currency)!;
  const formatter = new Intl.NumberFormat('en', { style: 'currency', currency: tranx.currency });
  const balanceAfter = tranx.balanceBefore + tranx.amount;

  return (
    <div className="view_history">
      <BackLink text="Back" to={`/dashboard/bank/${tranx.walletId}`} />

      <div className="about_view_history">
        <p className="track bold">Track Transfer</p>
        <div className="conv_div">
          <div className="left_conv">
            <img src={nig} alt="nig" />
            <img src={arrow} alt="convert" />
            <img src={eng} alt="eng" />
          </div>
          <button className={`stat_btn ${tranx.status}`}>{tranx.status}</button>
        </div>

        <div className="details_box">
          <p className="amount_type">{tranx.amount} {tranx.currency}</p>

          <div className="cover_all_det">
            <div className="each_det">
              <div className="right_det">
                <p>Reference Code</p>
              </div>
              <div className="left_det">
                <p>AGTSJIU86S</p>
              </div>
            </div>
            <div className="each_det">
              <div className="right_det">
                <p>Amount tendered</p>
              </div>
              <div className="left_det">
                <p>{tranx.amount} {tranx.currency}</p>
              </div>
            </div>
            <div className="each_det">
              <div className="right_det">
                <p>Currency</p>
              </div>
              <div className="left_det">
                <img src={tranxCurrency.flag} alt="eng" />
                <p>{tranx.currency}</p>
              </div>
            </div>
            <div className="each_det">
              <div className="right_det">
                <p>Exchange rate</p>
              </div>
              <div className="left_det">
                <p>{formatter.format(1)} = {formatter.format(1)}</p>
              </div>
            </div>
            <div className="each_det">
              <div className="right_det">
                <p>Date & time</p>
              </div>
              <div className="left_det">
                <p>{format(new Date(tranx.createdAt), 'PP p')}</p>
              </div>
            </div>
            <div className="each_det">
              <div className="right_det">
                <p>Available Balance </p>
              </div>
              <div className="left_det">
                <p>{formatter.format(balanceAfter)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TransactionDetail() {
  const { transactionId } = useParams();

  const walletTranxDetailQuery = useWalletTransactionQuery(Number(transactionId));

  return (
    <div className="history_box">
      <div className="box_title">
        <p className="bold">Transaction History</p>
        <input type="date" />
      </div>
      <div className="box_content">
        <ComponentLoader
          queries={[walletTranxDetailQuery]}
          component={TransactionDetailView}
          componentPropsFn={(tranx: WalletTransactionMinimal) => ({ tranx })}
        />
      </div>
    </div>
  );
}
