import React from "react";
import InputPhone from "components/InputFields/inputPhone";
// import PhoneInputField from "components/InputFields/PhoneInputField";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { InferType, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useMutation } from "@tanstack/react-query";
import { requestPhoneVerification } from "../../lib/api";
import { useAppInfo } from "../../default/queries";
import { useProfile } from "./queries";
import { AppInfo } from "../../lib/api/requestTypes";
import { User } from "../../lib/api/apiTypes";
import { ComponentLoader } from "../../components/ComponentLoader";

const phoneUtil = PhoneNumberUtil.getInstance();

const verifySchema = object({
	mobile: string().required().test('is-valid-mobile', 'Mobile number is not valid', (value: string) => {
		try {
			return phoneUtil.isValidNumber(phoneUtil.parse(value));
		} catch (e) { return false; }
	}),
});

type VerifySchema = InferType<typeof verifySchema>;

type VerifyPhoneFormProps = {
	appInfo: AppInfo,
	user: User,
}
const VerifyPhoneForm = ({ appInfo, user }: VerifyPhoneFormProps) => {
	const methods = useForm({
		resolver: yupResolver(verifySchema),
	});
	const navigate = useNavigate();
	const mutation = useMutation({
		mutationFn: requestPhoneVerification,
		onSuccess(response) {
			navigate('/otp', { state: response.data });
		},
	});

	const onSubmit = (data: VerifySchema) => mutation.mutate(data.mobile);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
			>
				<InputPhone
					countries={appInfo.supportedCountries}
					defaultCountry={appInfo.supportedCountries.find(country => country.code === user.country)}
				/>
				<SubmitBtn
					disabled={mutation.isPending}
					isLoading={mutation.isPending}
					btnText='Send Verification Code'
				/>
			</form>
		</FormProvider>
	);
};

const VerifyPhone = () => {
	const appInfoQuery = useAppInfo();
	const userQuery = useProfile();

	const componentPropsFn = (appInfo: AppInfo, user: User) => ({ appInfo, user });

	return (
		<div className="pd_verify_phone">
			<div className="center_container">
				<p className="header_text">Verify your mobile number</p>
				<p className="sub_header_text">
					We are verifying your mobile number to keep your account secure 🔐. A
					code will be sent to you
				</p>

				<ComponentLoader
					component={VerifyPhoneForm}
					queries={[appInfoQuery, userQuery]}
					componentPropsFn={componentPropsFn}
				/>

				<Link className="later_link" to="/">
					I will do this later
				</Link>
			</div>
		</div>
	);
};

export default VerifyPhone;
