import React, { useState } from "react";
import { Link, useMatch } from "react-router-dom";
import Home from "../../assets/drawables/sidebar/home.svg";
import Market from "../../assets/drawables/sidebar/market.svg";
import Bank from "../../assets/drawables/sidebar/bank.svg";
import Profile from "../../assets/drawables/sidebar/profile.svg";
import Portfolio from "../../assets/drawables/sidebar/portfolio.svg";
import Setting from "../../assets/drawables/sidebar/setting.svg";
import Referal from "../../assets/drawables/sidebar/referal.svg";
import Support from "../../assets/drawables/support.svg";
import stack from "../../assets/drawables/sidebar/stack.svg";
import arr from "../../assets/drawables/sidebar/arr.svg";
import "./style.scss";
import cn from "classnames";

function CustomLink({ children, to }: React.PropsWithChildren<{ to: string }>) {
  const match = useMatch(to ? `/dashboard/${to}/*` : '/dashboard');

  return (
    <Link className={cn('item', { active: match })} to={to}>
      {children}
    </Link>
  );
}

const Sidebar = () => {
  const [toggleNav, setToggleNav] = useState(false);

  return (
    <>
      <div className={cn('pd_sidebar', { closed: toggleNav })}>
        <div
          onClick={() => setToggleNav(value => !value)}
          className="open_up"
        >
          <img src={arr} className="rightimg" alt="arr" />
          <img src={stack} className="midimg" alt="stack" />
          <img src={arr} className="leftimg" alt="arr" />
        </div>

        <div className="link_side">
          <CustomLink to="">
            <img src={Home} alt="home" className="iconSide" />
            <p>Home</p>
          </CustomLink>

          <CustomLink to="markets">
            <img src={Market} alt="markets" className="iconSide" />
            <p>Markets</p>
          </CustomLink>

          <CustomLink to="portfolio">
            <img src={Portfolio} alt="portfolio" className="iconSide" />
            <p>Portfolio</p>
          </CustomLink>

          <CustomLink to="bank">
            <img src={Bank} alt="bank" className="iconSide" />
            <p>Bank</p>
          </CustomLink>

          <CustomLink to="profile">
            <img src={Profile} alt="profile" className="iconSide" />
            <p>Profile</p>
          </CustomLink>

          <CustomLink to="referral">
            <img src={Referal} alt="referral" className="iconSide" />
            <p>Referral</p>
          </CustomLink>

          <CustomLink to="settings">
            <img src={Setting} alt="settings" className="iconSide" />
            <p>Settings</p>
          </CustomLink>

          <a href="/" className="item support2">
            <img src={Support} alt="support" className="iconSide2" />
            <p>Support</p>
          </a>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
