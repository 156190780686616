import React from "react";
import "./style.scss";
import CircularProgress from "@mui/material/CircularProgress";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { Link } from "react-router-dom";

type ProcessingModalProps = {
	loading?: boolean
	title?: string,
	loadText?: string,
	isHome?: boolean,
	desc?: string,
	btnText?: string,
	onBtnClick?: () => void,
}
/** @todo Split in processing and success modals */
const ProcessingModal = ({
	loading = false,
	title = '',
	loadText = 'Please wait...',
	isHome = false,
	desc = '',
	btnText = '',
	onBtnClick = () => {},
}: ProcessingModalProps) => {
	if (loading) {
		return (
			<div className="pd_progress_box">
				{" "}
				<CircularProgress color="success" />{" "}
				<p className="successP">Processing</p>
				<p className="wait">{loadText}</p>
			</div>
		);
	}

	return (
		<div className="pd_suc_modal">
			<p className="successP">{title}</p>
			<p className="story">{desc}</p>
			{btnText && <SubmitBtn btnText={btnText} onClick={onBtnClick} />}
			{isHome && (
				<Link to="/dashboard" className="">
					Back Home
				</Link>
			)}
		</div>
	);
};

export default ProcessingModal;
