import { DeepPartial } from "react-hook-form";
import { Address, Auth, Country, Currency, ID, User } from "./apiTypes";

export type AppInfo = {
	identity: {
		options: { label: string, value: string }[],
	},
	supportedCountries: Country[],
	supportedCurrencies: Currency[],
};

export type UserSignupDto = {
	country: string,
	email: string,
	password: string,
	referralCode?: string,
};

export type UserSignupData = {
	auth: Auth,
};

export type UserLoginDto = {
	email: string,
	password: string,
};

export type UserLoginData = {
	user: User,
	auth: Auth,
};

export type RequestPhoneVerificationData = {
	hash: string,
	auth: string,
}

export type VerifyPhoneNumberDto = {
	hash: string,
	token: string,
};

export type UpdateProfileDto = DeepPartial<{
	name: string | null,
	gender: string | null,
	dob: string | null,
	address: Omit<Address, 'id' | 'country'>,
}>;

export enum FileUploadIntent {
	ADDRESS_PROOF = 'address-proof',
	IDENTITY = 'identity',
}

/** @todo Update once wallet-to-wallet transfer flow is implemented */
export type CreateTransferRecipientDto = {
	type: 'external-bank-account',
	name: string,
	bankCode: string,
	bankName: string,
	country: string,
	accountNumber: string,
	email: string,
	phone?: string,
	currency: string,
};

export type CreateTransferDto = {
	sendingAmount: number,
	fromCurrency: string,
	toCurrency: string,
	recipientId: ID,
};

export type GetWalletTransactionQuery = Partial<{
	walletId: ID,
	currency: string,
	limit: number,
	cursor: number,
}>;
