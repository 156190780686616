import React, { useEffect } from "react";
import dropIcon from "../../assets/drawables/dropIcon.svg";
import indicate from "../../assets/drawables/indicate.svg";
import { useState } from "react";
import EachLocation from "./EachLocation";
import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";
import InputErrorMsg from "./InputErrorMsg";
import { PhoneNumberUtil } from "google-libphonenumber";
import { Country } from "../../lib/api/apiTypes";

const phoneUtil = PhoneNumberUtil.getInstance();

function parsePhone(number: string) {
  try {
    return phoneUtil.parse(number);
  } catch (e) {
    return null;
  }
}

type InputPhoneProps = React.HTMLProps<HTMLInputElement> & {
  name?: string,
  label?: string,
  isMsg?: boolean,
  countries: Country[],
  defaultCountry?: Country,
};

const InputPhone = ({
  name = 'mobile',
  label = '',
  isMsg = false,
  countries,
  defaultCountry,
  ...props
}: InputPhoneProps) => {
  const [selected, setSelected] = useState(defaultCountry ?? countries[0]);
  const [show, setShow] = useState(false);
  const { control, watch, formState: { errors } } = useFormContext();

  const number = parsePhone(watch(name));
  const countryCode = number?.getCountryCode()
  useEffect(() => {
    if (!countryCode) return;

    const country = countries.find(item => item.callingCode === `${countryCode}`);
    if (country) setSelected(country);
  }, [countries, countryCode])

  const error = errors.mobile;

  return (
    <div className="pd_input_phone">
      <label htmlFor="">{label}</label>
      <div className="pd_mobile_container">
        <div
          onClick={() => {
            if (props.disabled) return;
            setShow(show => !show);
          }}
          className="number_div"
        >
          <div className="current_hold">
            <img src={selected.flag} alt="flag" className="flag" />
            <p>+{selected.callingCode}</p>
            <img src={dropIcon} alt="drop" className="drop" />
          </div>
        </div>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const prefix = `+${selected.callingCode}`;
            const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              return field.onChange(`+${selected.callingCode}${e.target.value.replace(/^0+/, '')}`);
            }

            let value = `${field.value ?? ''}`;
            if (value.startsWith(prefix)) {
              value = value.substring(prefix.length);
            }

            return (
              <input
                ref={field.ref}
                type="tel"
                value={value}
                onBlur={field.onBlur}
                onChange={onChange}
                {...props}
              />
            )
          }}
        />
        <div className={classNames('drop_down', { show })}>
          <div className="overflow">
            <div className="all_country">
              <div className="location_list">
                {countries.map((item) => {
                  return (
                    <EachLocation
                      key={item.code}
                      onClick={() => {
                        setSelected(item);
                        setShow(false);
                      }}
                      code={`+${item.callingCode}`}
                      flag={item.flag}
                      name={item.name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && <InputErrorMsg errMsg={`${error.message}`} />}

      {isMsg && (
        <div className="msg_side">
          <img src={indicate} alt="indicate" />
          <p>SMS will be sent to this mobile number</p>
        </div>
      )}
    </div>
  );
};

export default InputPhone;
