import { Route, Routes, } from "react-router-dom";
import LandingPage from "pages/landing";
import Signup from "../pages/Authentication/signup";
import VerifyPhone from "../pages/Authentication/verifyPhone";
import Otp from "../pages/Authentication/Otp";
import Login from "../pages/Authentication/login";
import SelectForm from "../pages/Authentication/CompleteRegistration/selectForm";
import PersonalInfo from "../pages/Authentication/CompleteRegistration/personalInfo";
import IdentityInfo from "../pages/Authentication/CompleteRegistration/proofOfIdentity";
import MarketPage from "../pages/Market/index";
import CurrencyPage from "../pages/Market/currencypage";
import DashboardLayout from "components/Templates/dashboard";
import Home from "../pages/Dashboard/Home";
// import Bank from "../pages/Dashboard/Bank";
import MarketDashboard from "../pages/Dashboard/Market";
import EachMarket from "../pages/Dashboard/Market/EachMarket";
import CardInvest from "../pages/Dashboard/Market/Invest/index";
import SetCard from "../pages/Dashboard/Market/SetCard";
import SetBank from "../pages/Dashboard/Market/SetBank";
import Receipt from "../pages/Dashboard/Market/Receipt";
import SendMoneyIce from "../pages/Dashboard/Market/SendMoneyIce";
import Setting from "../pages/Dashboard/Settings";
import Notification from "../pages/Dashboard/Settings/notification";
import Language from "../pages/Dashboard/Settings/language";
import LoginSetting from "../pages/Dashboard/Settings/loginSetting";
import TwoFactor from "../pages/Dashboard/Settings/twofactor";
import Portfolio from "../pages/Dashboard/Portfolio";
import EachPortfolio from "../pages/Dashboard/Portfolio/EachPortfolio";
import UserCard from "../pages/Dashboard/Bank/BankCard/index";
import CardTrans from "../pages/Dashboard/Bank/BankCard/CardTrans";
// import Account from "../pages/Dashboard/Bank/Account";
// import ByCurrency from "../pages/Dashboard/Referral/ByCurrency";
import Profile from "../pages/Dashboard/Profile";
import Sell from "../pages/Dashboard/Portfolio/Sell";
import Convert from "../pages/Dashboard/Convert";
import HowItWorks from "../pages/HowItWorks";
// import CreateAccount from "../pages/Dashboard/Bank/Account/CreateAccount";
// import AccountCreated from "../pages/Dashboard/Bank/Account/AccountCreated";
// import NoAccountTransaction from "../pages/Dashboard/Bank/Account/NoAccountTransaction";
// import ShowTransactionDetails from "../pages/Dashboard/Bank/Account/ShowTransactionDetails";
// import ViewTransactionDetails from "../pages/Dashboard/Bank/Account/ViewTransactionDetails";
import BankIce from "../pages/Dashboard/BankICE";
import CreateAccountIce from "../pages/Dashboard/BankICE/CreateAccount";
import EachBankIce from "../pages/Dashboard/BankICE/EachBankIce";
import AboutBankCard from "../pages/Dashboard/BankICE/Card/AboutBankCard";
import ReferralPage from "../pages/Dashboard/ReferralPage";
import { Logout } from "../pages/Authentication/logout";
import { UserGuard } from "./guards/user";
import { SendMoneyWizard } from "../pages/Dashboard/SendMoney/SendMoneyWizard";
import { TransactionList } from "pages/Dashboard/BankICE/EachBankIce/TransactionList";
import { TransactionDetail } from "pages/Dashboard/BankICE/EachBankIce/TransactionDetail";
import { Topup } from "pages/Dashboard/BankICE/EachBankIce/Topup";
import { Referral } from "pages/Authentication/referral";


export const Router = () => {
	return (
		<Routes>
			{/* public routes */}
			<Route path="/" element={<LandingPage />} />
			<Route path="/business" element={<LandingPage />} />
			<Route path="/r/:code" element={<Referral />} />
			<Route path="/signup/:account" element={<Signup />} />
			<Route path="/verify" element={<VerifyPhone />} />
			<Route path="/otp" element={<Otp />} />
			<Route path="/login" element={<Login />} />
			<Route path="/logout" element={<Logout />} />
			<Route path="/complete" element={<SelectForm />} />
			<Route path="/complete/profile" element={<PersonalInfo />} />
			<Route path="/complete/identity" element={<IdentityInfo />} />
			<Route path="/market" element={<MarketPage />} />
			<Route path="/market/:id" element={<CurrencyPage />} />
			<Route path="/how-it-works" element={<HowItWorks />} />

			{/* private routes */}

			<Route element={<UserGuard />}>
				<Route path="/dashboard" element={<DashboardLayout />}
				>
					<Route index element={<Home />} />
					{/* you can change the component here, please do no change the path */}
					<Route path="markets" element={<MarketDashboard />} />
					<Route path="markets/:id" element={<EachMarket />} />

					<Route path="portfolio" element={<Portfolio />} />
					<Route path="portfolio/:id" element={<EachPortfolio />} />
					{/* <Route path="bank" element={<Bank />} /> */}
					<Route path="bank" element={<BankIce />} />
					<Route path="bank/:walletId" element={<EachBankIce />}>
						<Route index element={<TransactionList />} />
						<Route path="transaction/:transactionId" element={<TransactionDetail />} />
						<Route path="topup" element={<Topup />} />
					</Route>
					<Route path="bank/card/:id" element={<AboutBankCard />} />

					<Route path="profile" element={<Profile />} />

					{/* <Route path="referral" element={<Overview />} /> */}
					<Route path="referral" element={<ReferralPage />} />
					<Route path="settings" element={<Setting />} />
					<Route path="settings/notification" element={<Notification />} />
					<Route path="settings/language" element={<Language />} />
					<Route path="settings/login" element={<LoginSetting />} />
					<Route path="settings/twofactor" element={<TwoFactor />} />
					<Route path="bank/UserCard" element={<UserCard />} />
					<Route path="bank/UserCard/CardTrans" element={<CardTrans />} />

					{/* <Route path="bank/Account" element={<Account />} />

					<Route
						path="bank/Account/AccountCreated"
						element={<AccountCreated />}
					/>
					<Route
						path="bank/Account/AccountCreated/NoAccountTransaction"
						element={<NoAccountTransaction />}
					/>
					<Route
						path="bank/Account/AccountCreated/ShowTransactionDetails"
						element={<ShowTransactionDetails />}
					/>
					<Route
						path="bank/Account/AccountCreated/ViewTransactionDetails"
						element={<ViewTransactionDetails />}
					/>
					<Route path="referral/ByCurrency" element={<ByCurrency />} /> */}
				</Route>
				{/* outside dashboard */}

				{/* bank */}

				<Route path="bank/create_account" element={<CreateAccountIce />} />

				<Route path="sell" element={<Sell />} />

				<Route path="invest" element={<CardInvest />} />
				<Route path="/invest/setcard" element={<SetCard />} />
				<Route path="/invest/setbank" element={<SetBank />} />
				<Route path="/invest/receipt" element={<Receipt />} />
				<Route path="/invest/sendmoney" element={<SendMoneyIce />} />
				{/* send money */}
				<Route path="/send" element={<SendMoneyWizard />} />
				{/* <Route path="recipient" element={<SetReceiveMethod />} />
				<Route
					path="recipient/select-recipient/:type"
					element={<SelectRecipient />}
				/>
				<Route
					path="recipient/existing/:id/:type"
					element={<ExistingRecipient />}
				/>
				<Route path="pay/setcard" element={<SetCardPay />} /> */}

				{/* <Route path="summary" element={<SummaryStep />} />
				<Route path="pay" element={<PayMethod />} />
				<Route path="pay/sendmoney" element={<SendMoneyIcePay />} />
				<Route path="pay/setbank" element={<SetBankPay />} /> */}
				<Route path="pay/receipt" element={<Receipt />} />
				<Route path="convert" element={<Convert />} />
			</Route>
		</Routes>
	);
}