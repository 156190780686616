import React from "react";
import picard from "../../assets/drawables/picard.svg";
import deleteIcon from "../../assets/drawables/delete.svg";
import reupload from "../../assets/drawables/reload.svg";
import "./style.scss";
import { useFormContext } from "react-hook-form";
import InputErrorMsg from "./InputErrorMsg";

interface ImageUploadProps {
  label: string,
  subLabel: string,
  formLabel: string,
}

const ImageUpload = ({ label, subLabel, formLabel }: ImageUploadProps) => {
  const { register, watch, setValue, formState: { errors } } = useFormContext();

  const error = errors[formLabel];
  const files: FileList = watch(formLabel);
  const fileUploaded  = files?.length ? files[0] : null;

  return (
    <div className="upload_file">
      <p className="text_head">{label} *</p>
      <div className="cover_file">
        <label htmlFor={formLabel} className="label">
          {fileUploaded ? null : (
            <>
              {" "}
              <div className="picard_container">
                <img src={picard} alt="picard" />
                <p>{subLabel}</p>
              </div>
              <p className="upload">Upload File</p>
            </>
          )}

          {fileUploaded && (
            <img className="loadedimg" src={URL.createObjectURL(fileUploaded)} alt="upload" />
          )}
        </label>
        <input
          // onChange={(e) => {
          //   if (e.target.files?.length) {
          //     setImg(URL.createObjectURL(e.target.files[0]));
          //   }
          // }}
          hidden
          type="file"
          accept="image/png, image/jpeg"
          id={formLabel}
          {...register(formLabel, {
            required: 'File is required',
          })}
        />
        {fileUploaded && (
          <div className="actions">
            <label htmlFor={formLabel} className="reupload">
              {" "}
              <img src={reupload} alt="delete" /> Reupload{" "}
            </label>
            <button
              type="button"
              onClick={() => setValue(formLabel, undefined)}
              className="delete"
            >
              {" "}
              <img src={deleteIcon} alt="delete" /> Delete{" "}
            </button>
          </div>
        )}

        {error && <InputErrorMsg errMsg={`${error.message}`} />}
      </div>
    </div>
  );
};

export default ImageUpload;
