import React, { PropsWithChildren, createContext, useCallback, useContext, useState } from "react";
import { MoneyTransfer } from "./types";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as api from "lib/api";
import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { APIResponse, InboundPayment, Transfer } from "../../../lib/api/apiTypes";

export const sendMoneySteps = ["amount", "recipient", "summary", "payment", "payment/card", "payment/wallet"] as const;
export type SendMoneyStep = typeof sendMoneySteps[number];

const SendMoneyContext = createContext({
	step: 'amount' as SendMoneyStep,
	gotoStep(_: SendMoneyStep) {},
	gotoNextStep() {},
	gotoPrevStep() {},
	transfer: {} as MoneyTransfer,
	updateTransfer(_: Partial<MoneyTransfer>) {},
	createTransferMutation: null as UseMutationResult<Transfer, unknown, void> | null,
	payWithCardMutation: null as UseMutationResult<APIResponse<InboundPayment>, unknown, void> | null,
	payWithWalletMutation: null as UseMutationResult<APIResponse, unknown, void> | null,
});
export const useSendMoneyContext = () => useContext(SendMoneyContext); 

export function SendMoneyProvider({ children }: PropsWithChildren) {
	const location = useLocation();
	const navigate = useNavigate();

	const [transfer, setTransfer] = useState<MoneyTransfer>({ paymentMethod: 'wallet' });

	const targetStep = location.hash.substring(1) as SendMoneyStep; // Remove '#'
	const step = sendMoneySteps.includes(targetStep) ? targetStep : 'amount';

	const gotoStep = useCallback((newStep: SendMoneyStep) => {
		if (sendMoneySteps.includes(newStep)) {
			navigate(`/send#${newStep}`);
		}
	}, [navigate]);
	const gotoNextStep = useCallback(() => {
		gotoStep(sendMoneySteps[sendMoneySteps.indexOf(step) + 1]);
	}, [gotoStep, step]);
	const gotoPrevStep = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const updateTransfer = useCallback((transferUpdate: Partial<MoneyTransfer>) => {
		setTransfer({ ...transfer, ...transferUpdate });
	}, [transfer]);

	const createTransferMutation = useMutation({
		mutationFn: () => api.createTransfer({
			sendingAmount: transfer.sendingAmount!,
			fromCurrency: transfer.fromCurrency!,
			toCurrency: transfer.toCurrency!,
			recipientId: transfer.recipient!.id,
		}),
		onSuccess(data) {
			updateTransfer({
				id: data.id,
				fee: data.fee,
				amountPayable: data.amountPayable,
			});
			gotoNextStep();
		},
		onError(error) {
			console.error(error);
		},
	});
	const payWithCardMutation = useMutation({
		mutationFn: () => api.payTransferWithCard(transfer.id!),
		onError(error) {
			console.error(error);
		},
	});
	const payWithWalletMutation = useMutation({
		mutationFn: () => api.payTransferWithWallet(transfer.id!),
		onError(error) {
			console.error(error);
		},
	});

	if (step !== 'amount' && !(transfer.sendingAmount || transfer.fromCurrency)) {
		return <Navigate to='/send#amount' />;
	}

	return (
		<SendMoneyContext.Provider value={{
			step,
			gotoStep,
			gotoNextStep,
			gotoPrevStep,
			transfer,
			updateTransfer,
			createTransferMutation,
			payWithCardMutation,
			payWithWalletMutation,
		}}>
			{children}
		</SendMoneyContext.Provider>
	);
}
