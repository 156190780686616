import { useState } from "react";
import './style.scss';
import ConvertCurrencyDropDown from "components/InputFields/convertCurrencyDropDown";
import indicate from "assets/drawables/indicate.svg";
import greenArr from "assets/drawables/greenArrow.svg";
import { useAppData } from "../../../../default/AppDataProvider";

export function NetWorth() {
	const { appInfo } = useAppData();
	const [selectedCurrency, setSelectedCurrency] = useState(appInfo.supportedCurrencies[0].code);

	return (
		<div className="money_box">
			<div className="first_box">
				<p className="top">See your total portfolio in </p>
				<div className="flex_currency">
					<ConvertCurrencyDropDown
						value={selectedCurrency}
						onChange={setSelectedCurrency}
						currencies={appInfo.supportedCurrencies}
					/>
					<img src={indicate} alt="indicate" />
				</div>
			</div>
			<div className="sec_box">
				<p className="port">TOTAL NET WORTH</p>
				<p className="money">$120,345.20</p>
				<div className="green">
					<img src={greenArr} alt="green" />
					<p>+1.47% ($3.21)</p>
				</div>
			</div>
			<p className="date">10.03.2022 10:00AM</p>
		</div>
	);
}
