import "./style.scss";
import greenArr from "assets/drawables/greenArrow.svg";
import redArr from "assets/drawables/redArrow.svg";
import Marquee from "react-fast-marquee";
import { useMemo } from "react";
import { useAppData } from "default/AppDataProvider";
import { generateMarketAssets } from "lib/random";

type MarketChange = {
  currencyCode: string,
  change: number,
}

const numberFormatter = new Intl.NumberFormat("en-US",{
  style: "percent",
  maximumFractionDigits: 2,
});

function SliderItem({ currencyCode, change }: MarketChange) {
  const isNegative = change < 0;
  const colour = isNegative ? "red" : "green";
  const imgSrc = isNegative ? redArr : greenArr;

  return (
    <div className="eachConversion">
      <p className="name">{currencyCode}</p>
      <img src={imgSrc} alt={`${colour} arrow`} />

      <p className={`percent ${colour}`}>
        {numberFormatter.format(change)}
      </p>
    </div>
  );
}

export default function ListConversion() {
  const { appInfo } = useAppData();

  const assets = useMemo(() => generateMarketAssets(appInfo.supportedCurrencies), [appInfo.supportedCurrencies]);

  return (
    <Marquee
      className="listConversion"
      speed={40}
      pauseOnHover
      pauseOnClick
      // play={false}
      autoFill
    >
      {assets.map((asset) => (
        <SliderItem key={asset.currency.code} currencyCode={asset.currency.code} change={asset.change} />
      ))}
    </Marquee>
  );
};
