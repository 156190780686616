import React from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import OtpInput from "react-otp-input";
import { useState } from "react";
import SubmitBtn from "../../components/SubmitBtn/SubmitBtn";
import { useMutation } from "@tanstack/react-query";
import { verifyPhoneNumber } from "../../lib/api";

const Otp = () => {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: verifyPhoneNumber,
    onSuccess() {
      navigate('/complete');
    }
  })
  const [otp, setOtp] = useState('');

  const location = useLocation();

  const otpIsValid = /^\d{5}$/.test(otp);
  const onSubmit = () => {
    if (otpIsValid) {
      mutation.mutate({ hash: location.state.hash, token: otp });
    }
  };

  if (!location.state?.hash) {
    return (<Navigate to='/verify' />)
  }

  return (
    <div className="pd_verify_phone pd_otp">
      <div className="center_container">
        <p className="header_text">Enter Code</p>
        <p className="sub_header_text">
          A 5-digit code has been sent to +234808********90
        </p>
        <div className="otp_wrap">
          <OtpInput
            value={otp}
            onChange={(value) => setOtp(value)}
            numInputs={5}
            inputStyle='inputStyle'
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <SubmitBtn
          disabled={!otpIsValid && mutation.isPending}
          isLoading={mutation.isPending}
          btnText='Submit Code'
          onClick={onSubmit}
        />

        <button className="resend_btn">Resend</button>
        <Link className="later_link" to="/complete">
          I will do this later
        </Link>
      </div>
    </div>
  );
};

export default Otp;
