import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import house from "assets/drawables/house.svg";
import { Link } from "react-router-dom";
import SendMoneyHeader from "../../widget/SendMoneyHeader";
import "./style.scss";
import { useSendMoneyContext } from "../../SendMoneyProvider";
import { Alert } from "@mui/material";

export const PaywithWallet = () => {
  const { transfer, payWithWalletMutation } = useSendMoneyContext();

  const currencyFormatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: transfer.fromCurrency });
  const amountPayable = currencyFormatter.format(transfer.amountPayable!);

  const btnOnClick = () => payWithWalletMutation?.mutate();

  const error = payWithWalletMutation?.error;

  return (
    <div className="pd_set_bank send_ice">
      <SendMoneyHeader active="payment" />
      <div className="main_aspect">
        {error instanceof Error &&  (
          <Alert severity="error" onClose={() => payWithWalletMutation?.reset()}>
            {error.message}
          </Alert>
        )}
        <div className="type_box">
          <div className="cover_house">
            <img src={house} alt="house" />
          </div>
          <p className="balance">Available Balance</p>
          <p className="amount">$120,345.20</p>
          <SubmitBtn
            onClick={btnOnClick}
            isLoading={payWithWalletMutation?.isPending}
            className="green"
            btnText={`Pay ${amountPayable}`}
          />
          <Link to="/send#payment" className="pay_link">
            Use another method
          </Link>
        </div>
      </div>
    </div>
  );
};
