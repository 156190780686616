import React from "react";
import "./style.scss";
import yellowbtn from "../../assets/drawables/yellowarrow.svg";
import { useNavigate } from "react-router-dom";

type BackBtnProps = {
  onClick?: () => void,
}
const BackBtn = ({ onClick }: BackBtnProps) => {
  const navigate = useNavigate();

  const clickHandler = onClick || (() => navigate(-1));

  return (
    <button onClick={clickHandler} className="yellow_btn">
      <img src={yellowbtn} alt="yellow" />
      Back
    </button>
  );
};

export default BackBtn;
