import { Box, CircularProgress } from "@mui/material";

type CircularLoadingProps = {
	marginY?: number,
};
export const CircularLoading = ({ marginY = 3 }: CircularLoadingProps) => {
	return (
		<Box display='flex' justifyContent='center' marginY={marginY}>
			<CircularProgress />
		</Box>
	);
}