"./style.scss";

import { useState } from "react";
import OtpInput from "react-otp-input";

const ThirdStep = ({ display }) => {
  const [otp, setOtp] = useState("");
  const [otp2, setOtp2] = useState("");
  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleChange2 = (otp) => {
    setOtp2(otp);
  };
  return (
    <div
      className="pd_third_step"
      style={{ display: display ? "block" : "none", marginTop: "34px" }}
    >
      <p className="set">
        Set a 4 digit card PIN. Use your card PIN to make transactions
      </p>

      <div className="cover_all_otp">
        <div className="otp_wrap">
          <label>Enter PIN</label>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            inputType="password"
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div className="otp_wrap">
          <label>Confirm PIN</label>
          <OtpInput
            value={otp2}
            onChange={handleChange2}
            numInputs={4}
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
