import { WalletTransactionMinimal } from "lib/api/apiTypes";
import EachHistory from "../EachHistory";
import inv from "assets/drawables/inv.svg";
import "./style.scss";
import { useWalletContext } from "./WalletProvider";

type TransactionListViewProps = {
	transactions: WalletTransactionMinimal[],
}
function TransactionListView({ transactions }: TransactionListViewProps) {
	if (!transactions.length) {
		return (
			<div className="empty_history">
				<img src={inv} alt="inv" />
				<p className="noTran">No Transactions</p>
				<p className="sub_noTran">You have no transaction history</p>
			</div>
		);
	}

	return (
		<div className="full_history">
			<p className="day">Today</p>
			{transactions.map((tranx, i) => <EachHistory key={i} tranx={tranx} />)}
		</div>
	);
}

export function TransactionList() {
	const { wallet } = useWalletContext();

	return (
		<div className="history_box">
			<div className="box_title">
				<p className="bold">Transaction History</p>
				<input type="date" />
			</div>
			<div className="box_content">
				<TransactionListView transactions={wallet!.transactions} />
			</div>
		</div>
	);
}
