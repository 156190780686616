import { Avatar } from "@mui/material";
import person from "assets/drawables/person.png";
import noref from "assets/drawables/noref.svg";
import leftArr from "assets/drawables/leftArr.svg";
import { UserReferee } from "lib/api/apiTypes";

function RefereeList({ referees }: { referees: UserReferee[] }) {
	return (
		<div className="cover_people">
			{referees.map((referee) => (
				<div className="each_people" key={referee.id}>
					<div className="left_people">
						<Avatar
							sx={{ width: 28, height: 28 }}
							alt="avatar"
							src={person}
						/>
						<div className="about_person">
							<p className="name">{referee.name ?? '<No name>'}</p>
							<p className="money">$14.23</p>
						</div>
					</div>
					<img src={leftArr} alt="leftArr" />
				</div>
			))}
		</div>
	);
}

function EmptyState() {
	return (
		<div className="noref">
			<img src={noref} alt="noref" />
			<p className="data">No data</p>
			<p className="appear">
				The people you refer will appear here
			</p>
		</div>
	);

}

interface ReferredUsersProps {
	referees: UserReferee[];
}
export function ReferredUsers({ referees }: ReferredUsersProps) {
	return (
		<div className="bottom_left_ref">
			<p className="people">People you have referred</p>
			{referees.length ? <RefereeList referees={referees} /> : <EmptyState /> }
		</div>
	);
}