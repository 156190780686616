import { ReactComponent as Mark } from "../../../../../../assets/drawables/mark.svg";
import woman from "../../../../../../assets/drawables/woman.svg";
import bag from "../../../../../../assets/drawables/bag.svg";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
const FirstStep = ({ display }) => {
  const [accountType, setAccountType] = useState("");
  const handleAccount = (val) => {
    console.log(val);
    setAccountType(val);
  };
  const { register } = useFormContext();
  const accountList = [
    { type: "Virtual card", img: woman },
    { type: "Physical card", img: bag },
  ];
  return (
    <div
      style={{ display: display ? "flex" : "none" }}
      className={`section flex`}
    >
      {accountList.map((el) => (
        <label
          className={`box ${accountType === el.type ? "active" : ""}`}
          key={el.type}
        >
          <div className="top">
            <img src={el.img} alt="" />
          </div>
          <div className="bottom">
            <p> {el.type.toUpperCase()} </p>
            {accountType === el.type && <Mark />}
          </div>
          <input
            hidden
            value={el.type}
            type={"radio"}
            name="account"
            {...register("account", {
              required: "Select An account",
              onChange: (e) => {
                handleAccount(e.target.value);
              },
            })}
          />
        </label>
      ))}
    </div>
  );
};

export default FirstStep;
