import React from "react";
import cn from "classnames";
import "./style.scss";

type SubmitBtnProps = React.HTMLProps<HTMLButtonElement> & {
  isLoading?: boolean,
  btnText: string,
  className?: string,
  style?: React.CSSProperties,
  disabled?: boolean,
  icon?: string,
  /** Not needed. Only added cos of typescript error */
  type?: 'submit' | 'reset' | 'button';
};

const SubmitBtn = ({
  isLoading,
  btnText,
  className,
  style,
  disabled,
  icon,
  ...others
}: SubmitBtnProps) => {
  const btnClass = cn('btn-primary submit-btn', className, { icon }, { disabled: disabled || isLoading });

  return (
    <button
      type='submit'
      style={style}
      className={btnClass}
      disabled={disabled || isLoading}
      {...others}
    >
      {isLoading ? (
        <div className="spin" />
      ) : icon ? (
        <>
          {" "}
          <img src={icon} alt="lock" /> {btnText}{" "}
        </>
      ) : (
        btnText
      )}
    </button>
  );
};

export default SubmitBtn;
