import { UseMutationResult } from "@tanstack/react-query";
import { APIResponse, InboundPayment } from "../../lib/api/apiTypes";
import { CardPaymentForm } from "./CardPaymentForm";
import { useCallback, useState } from "react";
import SmallModal from "../ModalPopUp/smallModal";
import ProcessingModal from "../ProcessingModal";
import { Alert } from "@mui/material";

type PaymentStage = 'pending' | 'in-progress' | 'success' | 'failure';

type CardPaymentProps = {
	/** Amount in smaller currency units */
	amount: number,
	changePaymentMethodLink?: string,
	currency: string,
	mutation: UseMutationResult<APIResponse<InboundPayment>, unknown, void>,
	onPayComplete?: () => void,
	paymentSuccessMsg: string,
}
export function CardPayment({
	amount,
	changePaymentMethodLink,
	currency,
	mutation,
	onPayComplete,
	paymentSuccessMsg,
}: CardPaymentProps) {
	const [stage, setStage] = useState<PaymentStage>('pending');
	const onSubmit = useCallback(() => {
		mutation.mutate(undefined, {
			onSuccess() {
				// Simulate payment submission
				setStage('in-progress');
				setTimeout(() => {
					setStage('success');
				}, 3000);
				if (onPayComplete) setTimeout(onPayComplete, 5000);
			},
		});
	}, [mutation, onPayComplete]);

	return (
		<>
			{mutation.error instanceof Error && (
				<Alert severity="error" onClose={() => mutation.reset()}>
					{mutation.error.message}
				</Alert>
			)}
			<CardPaymentForm
				amount={amount}
				changePaymentMethodLink={changePaymentMethodLink}
				currency={currency}
				isLoading={mutation.isPending}
				onSubmit={onSubmit}
			/>
			<SmallModal closeModal={() => setStage('pending')} openModal={stage !== 'pending'}>
				<ProcessingModal
					loading={stage === 'in-progress'}
					loadText="Please wait while we process your payment"
					title="Payment Success"
					desc={paymentSuccessMsg}
				/>
			</SmallModal>
		</>
	);
}
