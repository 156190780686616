import { FormProvider, useForm } from "react-hook-form";
import "./style.scss";
import vismaster from "assets/drawables/vismaster.svg";
import InputField from "../InputFields";
import { Box, Checkbox, SxProps, Typography } from "@mui/material";
import SubmitBtn from "../SubmitBtn/SubmitBtn";
import { formatAmount } from "../../lib/utils";
import { Link } from "react-router-dom";

const styling: Record<string, SxProps> = {
	checkbox: {
		p: 0,
		color: '#eaac0c',
		'&.Mui-checked': {
			color: '#eaac0c',
		},
	},
};

type CardPaymentFormProps = {
	amount: number,
	currency: string,
	changePaymentMethodLink?: string,
	isLoading?: boolean
	onSubmit: () => void,
}
export function CardPaymentForm({
	amount,
	changePaymentMethodLink,
	currency,
	isLoading = false,
	onSubmit,
}: CardPaymentFormProps) {
	const methods = useForm();

	return (
		<FormProvider {...methods}>
			<form className="card_payment_form" onSubmit={methods.handleSubmit(onSubmit)} action="">
				<InputField
					type="number"
					name="card_number"
					placeholder="1234 0384 3445 2389"
					id="card_number"
					label="Card Number"
					isMaster
				/>
				<div className="form-group-wrap">
					<InputField type="date" name="expiry_date" id="expiry_date" label="Expiry Date" />
					<InputField
						type="password"
						name="security_code"
						id="security_code"
						label="Security Code"
						isInd
					/>
				</div>
				<InputField
					type="text"
					name="name"
					placeholder="Raji Mustapha"
					id="name"
					label="Name on Card"
				/>
				<Box display="flex" alignItems="center" my={2} mx={0}>
					<Checkbox sx={styling.checkbox} defaultChecked color="secondary" />
					<Typography ml={0.5} variant="caption">Save this card details</Typography>
				</Box>
				<SubmitBtn
					isLoading={isLoading}
					className="green"
					btnText={`Pay ${formatAmount(amount, currency)}`}
				/>
				{changePaymentMethodLink && (
					<Link to={changePaymentMethodLink} className="pay_link">
						Change payment method
					</Link>
				)}
				<Box mt={3} textAlign="center">
					<Typography component="p" variant="caption">Payment secured by</Typography>
					<img src={vismaster} alt="vismaster" />
				</Box>
			</form>
		</FormProvider>
	);
}