import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearAuth } from "../../lib/api";

export function Logout() {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	useEffect(() => {
		clearAuth();
		queryClient.clear();
		navigate('/login');
	}, [navigate, queryClient]);

	return <></>;
}
