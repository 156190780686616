import "./style.scss";

type PageDescriptionProps = {
  title: string,
  description: string,
  date: string,
}
const PageDescription = ({ title, description, date }: PageDescriptionProps) => {
  return (
    <div className="pd_page_description">
      <p className="page_description_market_title">{title}</p>
      <p className="description">{description}</p>
      <p className="date">{date}</p>
    </div>
  );
};

export default PageDescription;
