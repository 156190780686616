import warning from "../../assets/drawables/warning.svg";
import goArr from "../../assets/drawables/goArr.svg";
import { Link } from "react-router-dom";
import "./style.scss";
const CompleteRegistrationPop = () => {
  return (
    <div className="pd_complete_registration_pop">
      <p>
        {" "}
        <img src={warning} alt="warning" /> Please complete your account
        verification.
      </p>
      <Link to="/">
        {" "}
        Complete Registration <img src={goArr} alt="go" />{" "}
      </Link>
    </div>
  );
};

export default CompleteRegistrationPop;
