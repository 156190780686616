import { useQuery } from "@tanstack/react-query";
import { getTransferRecipients, getWallet, getWalletTransaction, getWalletTransactions } from "../../../lib/api";
import { GetWalletTransactionQuery } from "../../../lib/api/requestTypes";

export const TransferQueryKeys = {
	recipients: ['transferRecipients'] as const,
};

export const WalletQueryKeys = {
	all: ['wallet'] as const,
	details: () => [...WalletQueryKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...WalletQueryKeys.details(), `${id}`] as const,
};

export const WalletTransactionQueryKeys = {
	all: ['wallet/transactions'] as const,
	lists: () => [...WalletTransactionQueryKeys.all, 'list'] as const,
	list: (filters: GetWalletTransactionQuery) => {
		const filtersKey = { ...filters, walletId: filters.walletId ? `${filters.walletId}` : undefined };
		return [...WalletTransactionQueryKeys.lists(), filtersKey] as const;
	},
	details: () => [...WalletTransactionQueryKeys.all, 'detail'] as const,
	detail: (id: number | string) => [...WalletTransactionQueryKeys.details(), `${id}`] as const,
};

export function useTransferRecipientsQuery() {
	return useQuery({
		queryKey: TransferQueryKeys.recipients,
		queryFn: getTransferRecipients,
	});
}

export function useWalletTransactionsQuery(walletId: number | string) {
	return useQuery({
		queryKey: WalletTransactionQueryKeys.list({ walletId }),
		queryFn: ({ queryKey })  => getWalletTransactions(queryKey[2]),
		// ...options,
	});
}

export function useWalletTransactionQuery(id: number | string) {
	return useQuery({
		queryKey: WalletTransactionQueryKeys.detail(id),
		queryFn: ({ queryKey })  => getWalletTransaction(queryKey[2]),
		// ...options,
	});
}

export function useWalletQuery(walletId: number | string) {
	return useQuery({
		queryKey: WalletQueryKeys.detail(walletId),
		queryFn: ({ queryKey })  => getWallet(queryKey[2]),
	});
}
