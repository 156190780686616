import fb from "assets/drawables/fb.svg";
import twitter from "assets/drawables/twi.svg";
import ig from "assets/drawables/ig.svg";
import instagram from "assets/drawables/in.svg";
import present from "assets/drawables/present.png";
import { useState } from "react";

interface ReferralLinkBoxProps {
	referralCode: string;
}
export function ReferralLinkBox({ referralCode }: ReferralLinkBoxProps) {
	const link = new URL(`/r/${referralCode}`, window.location.href).href;
	const linkWithoutProtocol = link.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '');

	const [linkCopyText, setLinkCopyText] = useState('Copy link');

	const copyLink = () => {
		navigator.clipboard.writeText(link);
		setLinkCopyText('Copied');
		setTimeout(() => setLinkCopyText('Copy link'), 2000);
	}

	return (
		<div className="right_ref">
			<img src={present} alt="present" className="present" />
			<p className="get">
				Get your friends to signup on ICE with your <br /> referral code
				and enjoy reward once they start <br /> investing on the platform
			</p>
			<p className="share">Share the referral link</p>
			<p className="copy">
				You can share your link by copying and sending it or sharing on
				your social media
			</p>
			<div className="copy_box">
				<p className="link">{linkWithoutProtocol}</p>
				<button onClick={copyLink}>{linkCopyText}</button>
			</div>
			<div className="icon_box">
				<img src={fb} alt="fb" />
				<img src={twitter} alt="fb" />
				<img src={ig} alt="fb" />
				<img src={instagram} alt="fb" />
			</div>
			<p className="terms">Read terms and conditions</p>
		</div>
	);
}