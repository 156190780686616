import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import "./style.scss";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { useNavigate } from "react-router-dom";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";
const CreateCardModal = ({ setOpenModal, setOpenModal2 }) => {
  const methods = useForm({
    mode: "all",
  });
  const navigate = useNavigate();

  const onSubmit = (val) => {
    console.log(val);
    setOpenModal(false);
    setOpenModal2(true);
    // navigate(`/signup/${val.account}`);
  };

  const TOTAL_STEPS = 3;
  const [presentStep, setPresentStep] = useState(1);
  const goNext = (num) => {
    setPresentStep(presentStep + num);
    console.log(methods.getValues());
    if (methods.formState.isValid) {
      console.log("valid");
    } else {
      console.log(methods.formState.errors, "not valid");
    }
  };

  return (
    <div className="pd_signup_modal">
      {presentStep === 1 && (
        <p className="sec_text">
          What type of account will you like to create?
        </p>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {presentStep >= 1 && (
            <FirstStep display={presentStep === 1 ? true : false} />
          )}

          {presentStep >= 2 && (
            <SecondStep display={presentStep === 2 ? true : false} />
          )}
          {presentStep >= 3 && (
            <ThirdStep display={presentStep === 3 ? true : false} />
          )}
          <SubmitBtn
            btnText="Create Card"
            disabled={!methods.formState.isValid}
            style={{
              display: presentStep === TOTAL_STEPS ? "flex" : "none",
            }}
          />
          <SubmitBtn
            style={{
              display: presentStep < TOTAL_STEPS ? "unset" : "none",
            }}
            type="button"
            disabled={!methods.formState.isValid}
            onClick={() => goNext(1)}
            className="btn-primary blue"
            btnText={"Proceed"}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateCardModal;
