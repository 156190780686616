import SupportBtn from "components/SupportBtn";
import NavIce from "../NavIce";
import CreateAccountBox from "./CreateAccountBox";
import "./style.scss";

export default function CreateAccountIce() {
  return (
    <div className="pd_create_account">
      <NavIce active="currency" title="Create Account" />
      <SupportBtn className="down" />

      <CreateAccountBox />
    </div>
  );
};
