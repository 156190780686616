import ListConversion from "components/ListConversion";
import "./style.scss";
import { Outlet } from "react-router-dom";
import { BackLink } from "./BackLink";
import { BalanceBox } from "./BalanceBox";
import { WalletProvider } from "./WalletProvider";

export default function EachBankIce() {
  return (
    <div className="pd_each_bank_ice">
      <ListConversion />
      <div className="cover_each_bank_ice">
        <WalletProvider>
          <BalanceBox />
          <BackLink text="Back to bank overview" to="/dashboard/bank" />
          <Outlet />
        </WalletProvider>
      </div>
    </div>
  );
}
