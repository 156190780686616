import React from "react";
import "./style.scss";
import mark from "../../../../../assets/drawables/markGreen.svg";
import cn from "classnames";

type PickBankItem = {
  id: string,
  name: string,
  desc: string,
  img: string,
}

type PickBankProps = {
  item: PickBankItem,
  active?: boolean,
  iceAmount?: number,
  onClick: (id: string) => void,
}
const PickBank = ({ active = false, iceAmount, item, onClick }: PickBankProps) => {
  return (
    <div
      onClick={() => onClick(item.id)}
      className={cn('pd_pick_bank', { active })}
    >
      {iceAmount && <p className="ice_amount">{iceAmount}</p>}
      <img src={mark} alt="mark" className="mark" />
      <div className="cover_img">
        <img src={item.img} alt="bank" />
      </div>
      <div className="text_side">
        <p className="top">{item.name}</p>
        <p className="bottom">{item.desc}</p>
      </div>
    </div>
  );
};

export default PickBank;
