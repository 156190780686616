import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function Referral() {
	const navigate = useNavigate();
	const { code } = useParams();

	useEffect(() => {
		navigate(`/signup/personal?referral-code=${code}`);
	}, [code, navigate]);

	return <></>;
}