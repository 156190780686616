import { Alert, Box, Typography } from "@mui/material";
import { useAppData } from "default/AppDataProvider";
import { useMemo, useState } from "react";
import SelectCurrencyIce from "../../../../components/InputFields/SelectCurrencyIce";
import SubmitBtn from "../../../../components/SubmitBtn/SubmitBtn";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createWallet } from "../../../../lib/api";
import { useNavigate } from "react-router-dom";
import { UserQueryKeys } from "../../../Authentication/queries";

export default function CreateAccountBox() {
	const { appInfo, user } = useAppData();
	const navigate = useNavigate();

	const availableCurrencies = useMemo(() => {
		const userCurrencySet = new Set(user?.wallets.map(wallet => wallet.currency));
		return appInfo.supportedCurrencies.filter((item) => !userCurrencySet.has(item.code));
	}, [appInfo.supportedCurrencies, user?.wallets]);
	const [currency, setCurrency] = useState<string | null>(null);

	const queryClient = useQueryClient();
	const createWalletMutation = useMutation({
		mutationFn: createWallet,
		onSuccess(data) {
			const wallet = data.data;
			queryClient.invalidateQueries({ queryKey: UserQueryKeys.profile });
			navigate(`/dashboard/bank/${wallet.id}`);
		},
	});

	const error = createWalletMutation?.error;

	return (
		<Box height={400} margin="auto" maxWidth={350}>
			<Typography marginBottom={3} fontFamily="myFirstFont" fontWeight={600} color="#0d458a" fontSize={17}>
				Select currency
			</Typography>
			{error instanceof Error && (
				<Box marginBottom={1}>
					<Alert severity="error" onClose={() => createWalletMutation?.reset()}>
						{error.message}
					</Alert>
				</Box>
			)}
			<SelectCurrencyIce
				value={currency}
				onChange={setCurrency}
				currencies={availableCurrencies}
			/>
			<SubmitBtn
				disabled={!currency}
				className="green"
				btnText="Proceed"
				isLoading={createWalletMutation.isPending}
				onClick={() => currency && createWalletMutation.mutate(currency)}
			/>
		</Box>
	);
}