import creditcard from "../../../../../assets/drawables/creditcard.svg";
const ActionCard = ({ type }) => {
  return (
    <div className="pd_action_card">
      <p className="ask">
        Are you sure you want to {type === "delete" ? "delete" : "deactivate"}{" "}
        this card?
      </p>
      <img src={creditcard} alt="credit" />
      <button className="">Cancel</button>
      <button className={`${type === "delete" ? "red" : ""}`}>
        {type === "delete"
          ? "Yes, I want to delete"
          : "Yes, I want to deactivate"}
      </button>
    </div>
  );
};

export default ActionCard;
