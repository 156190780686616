import InputField from "components/InputFields";
import ConvertCurrencyDropDown from "components/InputFields/convertCurrencyDropDown";
import SelectCurrencyIce from "components/InputFields/SelectCurrencyIce";
import "./style.scss";
const SecondStep = ({ display }) => {
  return (
    <div
      style={{ display: display ? "block" : "none", marginTop: "44px" }}
      className="pd_second_step"
    >
      <InputField
        type="text"
        name="card_name"
        placeholder="Ademola Akanbi"
        id="email"
        label="Card Name"
        errMsg="invalid input"
      />
      <div className="cov">
        <label htmlFor="">Currency</label>
        <SelectCurrencyIce handleCurrency={() => {}} />
      </div>

      <InputField
        type="text"
        name="amount"
        placeholder=""
        id="email"
        label="How much money do you want to fund the card with?"
        errMsg="invalid input"
      />
      <p className="charge">
        You will be charged NGN13,896.00 at NGN579.00 to USD1. Please note that
        this includes an additional card creation fee of NGN1,158.00 (USD2).
      </p>
    </div>
  );
};

export default SecondStep;
