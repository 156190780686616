import React from "react";
import greenArr from "../../assets/drawables/greenArrow.svg";
import redArr from "../../assets/drawables/redArrow.svg";
import "./style.scss";

type GrowthProps = {
  isRed?: boolean
  content: number | string
}

const Growth = ({ isRed = false, content }: GrowthProps) => {
  return (
    <div className="pd_growth_show">
      <img src={isRed ? redArr : greenArr} alt="" />
      <p className={isRed ? "red" : "green"}>{content}</p>
    </div>
  );
};

export default Growth;
