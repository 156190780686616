import ListConversion from "components/ListConversion";
import { useState } from "react";
import AccountIce from "./Account";
import CardIce from "./Card";
import "./style.scss";
import cn from "classnames";

type Tab = 'account' | 'card';

const BankIce = () => {
  const [active, setActive] = useState<Tab>("account");

  return (
    <div className="pd_bank_ice">
      <ListConversion />
      <div className="cover_remainder">
        <div className="filter_ice">
          <div
            onClick={() => setActive('account')}
            className={cn('eachfilter_ice', { active: active === 'account' })}
          >
            <p>Account</p>
            <div className="act_line"></div>
          </div>
          <div
            onClick={() => setActive('card')}
            className={cn('eachfilter_ice', { active: active === 'card' })}
          >
            <p>Card</p>
            <div className="act_line"></div>
          </div>
        </div>
        <div className="ice_main">
          {active === "account" && <AccountIce />}
          {active === "card" && <CardIce />}
        </div>
      </div>
    </div>
  );
};

export default BankIce;
