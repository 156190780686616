import React, { JSX, useState } from "react";
import "./style.scss";
import InputErrorMsg from "./InputErrorMsg";
import { useFormContext } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Indicate from "../../assets/drawables/indicate.svg";
import master from "../../assets/drawables/mastercard.svg";
import cn from "classnames";

interface InputFieldProps extends React.HTMLProps<HTMLInputElement> {
  id?: string;
  name: string;
  isIconDisabled?: boolean;
  iconPlaceholder?: JSX.Element;
  iconPosition?: string;
  className?: string;
  required?: boolean;
  label?: string;
  /** @todo Deprecate isMaster and isInd */
  isMaster?: boolean;
  isInd?: boolean;
  rounded?: boolean;
}

export default function InputField({
  type,
  id,
  name,
  isIconDisabled = true,
  iconPlaceholder,
  iconPosition = "right",
  className,
  required = false,
  label,
  isMaster = false,
  isInd = false,
  rounded = true,
  ...props
}: InputFieldProps) {
  const [showPassword, setShowPassword] = useState(true);
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name];

  return (
    <div className={cn('form-group', className)}>
      {label && !isInd && !isMaster && (
        <label htmlFor={id || name}>{label} {required && '*'}</label>
      )}
      {isInd && (
        <div className="cover_label">
          <label htmlFor={id || name}>{label}</label>
          <img src={Indicate} alt="indicator" />
        </div>
      )}
      {isMaster && (
        <div className="cover_label">
          <label htmlFor={id || name}>{label}</label>
          <img src={master} alt="indicator" />
        </div>
      )}
      <div
        className={cn('input-icon-wrap', {
          'icon-placeholder': iconPlaceholder,
          'is-invalid': error,
          rounded,
        })}
      >
        {type === "password" ? (
          <>
            <input
              autoComplete="off"
              type={showPassword ? "password" : "text"}
              id={id}
              {...register(name, {
                required: required ? "You must specify a password" : false,
              })}
              {...props}
              placeholder={props.placeholder}
            />
            <button
              disabled={props.disabled}
              onClick={() => setShowPassword((prev) => !prev)}
              type="button"
              className="icon icon-right"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </button>
          </>
        ) : (
          <>
            <input
              autoComplete="off"
              type={type}
              id={id || name}
              {...register(name, {
                required: required ? "This Field is required" : false,
              })}
              {...props}
              placeholder={props.placeholder}
            />
          </>
        )}
        {iconPlaceholder && (
          <div
            // disabled={isIconDisabled}
            className={`icon startIcon-${iconPosition}`}
          >
            {iconPlaceholder}
          </div>
        )}
      </div>

      {error && <InputErrorMsg errMsg={`${error.message}`} />}
    </div>
  );
};
