import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from "react";
import "./style.scss";
import cn from "classnames";

interface DropDownWrapperProps extends PropsWithChildren {
  action: ReactNode;
  className?: string;
}
export default function DropDownWrapper({ action, children, className }: DropDownWrapperProps) {
  const [showDropDown, setshowDropDown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (showDropDown && ref.current && !ref.current.contains(event.target as Node)) {
        setshowDropDown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDropDown]);

  return (
    <div ref={ref} className={cn('drop-down-wrapper', className)}>
      <div
        onClick={() => setshowDropDown(!showDropDown)}
        className="drop-down-action"
      >
        {action}
      </div>
      <div className={cn('drop-down-content', { 'show-drop-down': showDropDown })}>
        {children}
      </div>
    </div>
  );
};
