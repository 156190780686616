import React, { useEffect } from "react";
import InputField from "components/InputFields";
import AuthTemplate from "components/Templates/authTemplate";
import { FormProvider, useForm } from "react-hook-form";
import Indicate from "../../assets/drawables/indicate.svg";
import "./style.scss";
import { Alert, Checkbox } from "@mui/material";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import IconWidget from "components/IconWidget";
import google from "../../assets/drawables/google.svg";
import facebook from "../../assets/drawables/facebook.svg";
import apple from "../../assets/drawables/apple.svg";
import CustomFlagDropDown from "components/InputFields/customFlagDropDown";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { object, string, InferType } from 'yup';
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation } from "@tanstack/react-query";
import { hasAuth, signupUser } from "../../lib/api";
import { useAppInfo } from "../../default/queries";
import { ComponentLoader } from "../../components/ComponentLoader";
import { AppInfo, UserSignupDto } from "../../lib/api/requestTypes";

const signupSchema = object({
	country: string().required(),
	email: string().required().email(),
	password: string().required().min(8),
});

type SignupSchema = InferType<typeof signupSchema>;

type SignupFormProps = {
	appInfo: AppInfo,
};
const SignupForm = ({ appInfo }: SignupFormProps) => {
	const methods = useForm<SignupSchema>({
		resolver: yupResolver(signupSchema),
	});
	const navigate = useNavigate();
	const mutation = useMutation({
		mutationFn: signupUser,
		onSuccess() {
			navigate("/verify");
		}
	});

	const [searchParams] = useSearchParams();
	const onSubmit = (data: UserSignupDto) => {
		const referralCode = searchParams.get("referral-code");
		if (referralCode) data.referralCode = referralCode;
		mutation.mutate(data);
	};

	// Redirect to dashboard if user is already logged in
  useEffect(() => {
    if (hasAuth()) {
      navigate('/dashboard');
    }
  }, [navigate]);

	return (
		<FormProvider {...methods}>
			{mutation.isError && mutation.error instanceof Error && (
				<Alert severity="error">{mutation.error.message}</Alert>
			)}

			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<CustomFlagDropDown countries={appInfo.supportedCountries} />

				<InputField
					type="email"
					name="email"
					placeholder="stephen@gmail.com"
					id="email"
					label="Email Address"
				/>

				<InputField
					type="password"
					name="password"
					id="password"
					label="Password"
				/>
				<div className="indicate">
					<img src={Indicate} className="icon" alt="" />
					<p>
						Your password should be at least 8 characters, and include 1
						upper case letter, 1 lower case letter and 1 number
					</p>
				</div>
				<div className="check_container">
					<Checkbox defaultChecked color="secondary" />
					<p>
						I agree to ICE's <a href="/">Terms of Service</a>{" "}
					</p>
				</div>
				<SubmitBtn disabled={mutation.isPending} isLoading={mutation.isPending} btnText='Sign up' />
			</form>
		</FormProvider>
	);
};

const Signup = () => {
	const appInfoQuery = useAppInfo();
	const componentPropsFn = (appInfo: AppInfo) => ({ appInfo });

	return (
		<div className="pd_signup">
			<AuthTemplate>
				<p className="header_text">Create an account</p>

				<ComponentLoader
					component={SignupForm}
					queries={[appInfoQuery]}
					componentPropsFn={componentPropsFn}
				/>

				<p className="continue">Or Continue with</p>
				<div className="socials">
					<IconWidget>
						{" "}
						{/* <Google /> */}
						<img src={google} alt="" />
					</IconWidget>
					<IconWidget>
						{" "}
						{/* <Facebook /> */}
						<img src={facebook} alt="" />
					</IconWidget>
					<IconWidget>
						{" "}
						{/* <Apple />{" "} */}
						<img src={apple} alt="" />
					</IconWidget>
				</div>

				<p className="login_text">
					Already have an account?<Link to="/login"> Log in</Link>{" "}
				</p>
			</AuthTemplate>
		</div>
	);
}

export default Signup;
