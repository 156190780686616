import React from "react";

type EachLocationProps = {
  name: string,
  code: string,
  flag: string,
  onClick: () => void,
}
const EachLocation = ({ onClick, name, code, flag }: EachLocationProps) => {
  return (
    <div onClick={onClick} className="pd_each_location">
      <img src={flag} alt={`Flag for ${name}`} />
      <p className="code">{code}</p>
      <p className="country_name">{name}</p>
    </div>
  );
};

export default EachLocation;
