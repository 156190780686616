import { ReactNode } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { FunctionComponent } from "react"
import { CircularLoading } from "../CircularLoading";
import { Box, Typography } from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

type ErrorStateProps = {
	message?: string,
	marginY?: number,
};
export function ErrorState({ marginY = 3, message }: ErrorStateProps) {
	return (
		<Box display='flex' alignItems='center' flexDirection='column' marginY={marginY}>
			<Box>
				<ReportProblemIcon fontSize="large" color="warning" />
			</Box>
			{message && (<Typography>{message}</Typography>)}
		</Box>
	);
}

interface ComponentLoaderProps<T> {
	component: FunctionComponent<T>,
	componentPropsFn: (...results: any[]) => T & React.JSX.IntrinsicAttributes,
	renderError?: (error: Error) => ReactNode,
	renderLoading?: () => ReactNode,
	queries: UseQueryResult[],
};

export function ComponentLoader<T>({
	component: Component,
	componentPropsFn,
	renderError,
	renderLoading,
	queries,
}: ComponentLoaderProps<T>) {
	const loadingQuery = queries.find(q => q.status === 'pending' && q.isFetching);
	const errorQuery = queries.find(q => q.status === 'error');

	if (loadingQuery) {
		return renderLoading ? renderLoading() : <CircularLoading />;
	}

	if (errorQuery) {
		const error = errorQuery.error as Error;
		return renderError ? renderError(error) : <ErrorState message="Error occurred while loading data" />;
	}

	const props = componentPropsFn(...queries.map(q => q.data));
	return (
		<Component {...props} />
	);
}
