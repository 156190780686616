import slantcard from "../../../../../../assets/drawables/slantcard.png";
import "./style.scss";
const SuccessModal = ({ setOpenModal2 }) => {
  return (
    <div className="pd_suc_mod">
      <img src={slantcard} alt="slant" />
      <p className="verify">Request sent</p>
      <p className="not">
        You will be notified once your <br /> card is available
      </p>
      <button
        onClick={() => {
          setOpenModal2(false);
        }}
        className="close"
      >
        {" "}
        Close
      </button>
    </div>
  );
};

export default SuccessModal;
