import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import back from "../../../../assets/drawables/back.svg";

type BackLinkProps = {
  to: string,
  text: string,
}
export function BackLink({
  to,
  text,
}: BackLinkProps) {
  return (
    <Box marginY={2} paddingLeft={7}>
      <Link className="back_link" to={to}>
        <img src={back} alt="back" />
        <Typography color="#173963" paddingLeft={0.5} component='span' fontSize={12}>
          {text}
        </Typography>
      </Link>
    </Box>
  );
}