import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import React from "react";
import PickBank from "../../../Market/widget/PickBank/pickBank";
import SendMoneyHeader from "../../widget/SendMoneyHeader";
import "./style.scss";

import bankImg from "assets/drawables/bankImg.svg";
import atm from "assets/drawables/atm.svg";

import { SendMoneyStep, useSendMoneyContext } from "../../SendMoneyProvider";
import { PaymentMethod } from "lib/api/apiTypes";

const selection = [
  {
    name: "Bank",
    desc: "Pay now from your Bank USD account",
    img: bankImg,
    id: "wallet",
  },
  {
    name: "Debit/Credit Card",
    desc: "Pay using your credit/debit card. ",
    img: atm,
    id: "card",
  },
  // {
  //   name: "Bank Transfer",
  //   desc: "Pay now from your bank account. ",
  //   img: bankImg,
  //   id: "bank",
  // },
];

export const PaymentMethodStep = () => {
  const { gotoStep, transfer, updateTransfer } = useSendMoneyContext();

  const updatePaymentMethod = (id: string) => {
    updateTransfer({ paymentMethod: id as PaymentMethod });
  }

  const proceedToPayment = () => {
    gotoStep(`payment/${transfer.paymentMethod}` as SendMoneyStep);
  };

  return (
    <div className="pd_pay_method">
      <SendMoneyHeader active="payment" />
      <div className="main_aspect">
        <p className="first_text_send bold">How do you want to pay?</p>
        <div className="cover_bank_list">
          {selection.map((item) => {
            return (
              <PickBank
                item={item}
                active={transfer.paymentMethod === item.id}
                onClick={updatePaymentMethod}
                key={item.id}
              />
            );
          })}
        </div>
        <SubmitBtn
          onClick={proceedToPayment}
          btnText='Proceed to pay'
          className="green"
        />
      </div>
    </div>
  );
};
