import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { hasAuth } from "../../lib/api";

export function UserGuard() {
	const location = useLocation();

	if (!hasAuth()) {
		return <Navigate to="/login" state={{ from: location }} />;
	}

	return <Outlet />;
}
