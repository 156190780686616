import eng from "../../../../assets/drawables/england.svg";
import line from "../../../../assets/drawables/line.svg";
import "./style.scss";
import Trend from "react-trend";
import indicator from "../../../../assets/drawables/indicate.svg";
import cancel from "../../../../assets/drawables/cancel.svg";
import { FormProvider, useForm } from "react-hook-form";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import CurrencyPhone from "components/InputFields/currencyPhone";
import SupportBtn from "components/SupportBtn";
import { useState } from "react";

import Modal from "components/ModalPopUp";
import NewOldCardModal from "components/NewOldCardModal";
import SmallModal from "components/ModalPopUp/smallModal";

import ProcessingModal from "components/ProcessingModal";
import { useNavigate } from "react-router-dom";
import IceBankModal from "components/ICEBankModal";
import NavCard from "../../Market/NavCard";
const Sell = () => {
  const methods = useForm();
  const [active, setActive] = useState("amount");
  const [openModal, setOpenModal] = useState(false);

  const [iceAmount, setIceAmount] = useState("");
  const navigate = useNavigate();
  const onSubmit = (val) => {
    console.log(val);
  };

  console.log(iceAmount);
  return (
    <div className="pd_card_invest">
      <NavCard active={active} />
      <SupportBtn className={"down"} />
      <div className="cover_main">
        <div className="left">
          <p className="title">Currency Performance Brief</p>
          <div className="card_left">
            <div className="top">
              <div className="flag_side">
                {" "}
                <div className="flag_div">
                  <img src={eng} alt="end" /> <p>Yield(pa)</p>
                </div>{" "}
                <div className="flag_div line_div">
                  <img src={line} alt="line" />
                  <p>2.0</p>
                </div>{" "}
              </div>
              <p className="rate_side">
                Risk Rating
                <span>Low</span>
              </p>
            </div>
            <div className="bottom">
              <Trend
                data={[0, 10, 5, 22, 3.6, 11]}
                autoDraw
                autoDrawDuration={3000}
                autoDrawEasing="ease-in"
                gradient={["#34E36F"]}
              />
            </div>
          </div>
          <div className="indicate_div">
            <img src={indicator} alt="indicator" />
            <p>Last updated 5 mins ago</p>
          </div>
        </div>
        <div className="right">
          <div className="leftAmount">
            <div className="amount_box">
              <div className="currency_top">
                <p>Sell</p>
                <img src={cancel} alt="cancel" />
              </div>
              <div className="currency_form">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <CurrencyPhone label={"How much do you want to invest?"} />
                    <SubmitBtn
                      onClick={() => {
                        setActive("payment");
                        setOpenModal(true);
                      }}
                      btnText={"Continue"}
                    />
                  </form>
                </FormProvider>
              </div>
              <div className="indicate_div">
                <img src={indicator} alt="indicator" />
                <p>Last updated 5 mins ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SmallModal closeModal={() => setOpenModal(false)} openModal={openModal}>
        <ProcessingModal
          loading={true}
          title={"Success"}
          loadText={"Please wait while we process your transaction"}
          desc={
            "You have successfully sold $200. Your USD eWallet will be credited within 24 hours"
          }
        />
      </SmallModal>
    </div>
  );
};

export default Sell;
