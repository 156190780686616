import BackToMarket from "components/BackToMarket";
import england from "../../../../assets/drawables/england.svg";
import track from "../../../../assets/drawables/track.svg";
import redArr from "../../../../assets/drawables/redArrow.svg";
import greenArr from "../../../../assets/drawables/greenArrow.svg";
import line from "../../../../assets/drawables/line.svg";
import CurrencyDrop from "components/currencyDrop";
import { useState } from "react";
import nig from "../../../../assets/drawables/nigeria.svg";
import convertIcon from "../../../../assets/drawables/convert.svg";
import coverconvert from "../../../../assets/drawables/coverconvert.svg";
import union from "../../../../assets/drawables/Union.svg";
import indicate from "../../../../assets/drawables/indicate.svg";
import { ReactComponent as Candle } from "../../../../assets/drawables/candle.svg";
import { ReactComponent as Trend } from "../../../../assets/drawables/trend.svg";
import Chart from "react-apexcharts";
import EachTopCountry from "components/EachTopCountry";

import banner from "../../../../assets/drawables/banner.png";
import toolImg from "../../../../assets/drawables/toolImg.svg";
import "./style.scss";
import { Link } from "react-router-dom";
import EachNewsGrid from "components/EachNewsGrid";
import ConvertFlagDropDown from "components/InputFields/convertFlagDropDown";
import ConvertCurrencyDropDown from "components/InputFields/convertCurrencyDropDown";
import {
  VictoryCandlestick,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
} from "victory";
import moment from "moment";

const EachMarket = () => {
  const [tender, setTender] = useState("");
  const [buy, setBuy] = useState("");
  const [day, setDay] = useState("1D");
  const [chart, setChart] = useState("trend");
  const dayList = ["1D", "7D", "14D", "1M", "3M", "1Y", "MAX"];
  const chartList = ["trend", "candle"];
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
      type: "area",
      stacked: false,
    },
    stroke: {
      width: 1,
    },
    colors: ["#34E36F"],

    grid: {
      borderColor: "#868686",
      strokeDashArray: 5,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
    },

    xaxis: {
      categories: [
        "00:00",
        "02:00",
        "00:00",
        "02:00",
        "00:00",
        "02:00",
        "00:00",
        "02:00",
        "00:00",
        "02:00",
        "00:00",
        "02:00",
      ],
    },
  };
  const series = [
    {
      name: "rate",
      data: [5, 7, 9, 6, 4, 9, 11, 7.5, 13, 10, 6, 4],
    },
  ];
  const optionsCandle = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
      type: "category",
      stacked: false,
    },

    plotOptions: {
      candlestick: {
        colors: {
          upward: "#14FF00",
          downward: "#FF0000",
        },
      },
    },

    grid: {
      borderColor: "#868686",
      strokeDashArray: 5,
    },
  };
  const seriesCandle = [
    {
      name: "rate",

      data: [
        {
          x: "2:01",
          y: [6591, 6601.32, 6585, 6592],
        },
        {
          x: "2:02",
          y: [6593.13, 6596.01, 6590, 6593.34],
        },
        {
          x: "2:05",
          y: [6593.34, 6604.76, 6582.63, 6593.86],
        },
        {
          x: "2:07",
          y: [6593.86, 6604.28, 6586.57, 6600.01],
        },
        {
          x: "2:08",
          y: [6601.81, 6603.21, 6592.78, 6596.25],
        },
        {
          x: "3:01",
          y: [6596.25, 6604.2, 6590, 6602.99],
        },
        {
          x: "3:03",
          y: [6602.99, 6606, 6584.99, 6587.81],
        },
        {
          x: "3:05",
          y: [6587.81, 6595, 6583.27, 6591.96],
        },
        {
          x: "3:06",
          y: [6591.97, 6596.07, 6585, 6588.39],
        },
        {
          x: "3:08",
          y: [6587.6, 6598.21, 6587.6, 6594.27],
        },
        {
          x: "3:09",
          y: [6596.44, 6601, 6590, 6596.55],
        },
        {
          x: "4:01",
          y: [6598.91, 6605, 6596.61, 6600.02],
        },
        {
          x: "4:02",
          y: [6600.55, 6605, 6589.14, 6593.01],
        },
        {
          x: "4:04",
          y: [6593.15, 6605, 6592, 6603.06],
        },
        {
          x: "4:05",
          y: [6603.07, 6604.5, 6599.09, 6603.89],
        },
        // next
        // {
        //   x: "4:06",
        //   y: [6591, 6601.32, 6585, 6592],
        // },
        // {
        //   x: "4:07",
        //   y: [6593.13, 6596.01, 6590, 6593.34],
        // },
        // {
        //   x: "4:08",
        //   y: [6593.34, 6604.76, 6582.63, 6593.86],
        // },
        // {
        //   x: "4:09",
        //   y: [6593.86, 6604.28, 6586.57, 6600.01],
        // },
        // {
        //   x: "5:01",
        //   y: [6601.81, 6603.21, 6592.78, 6596.25],
        // },
        // {
        //   x: "5:01",
        //   y: [6596.25, 6604.2, 6590, 6602.99],
        // },
        // {
        //   x: "5:03",
        //   y: [6602.99, 6606, 6584.99, 6587.81],
        // },
        // {
        //   x: "5:05",
        //   y: [6587.81, 6595, 6583.27, 6591.96],
        // },
        // {
        //   x: "5:06",
        //   y: [6591.97, 6596.07, 6585, 6588.39],
        // },
        // {
        //   x: "5:08",
        //   y: [6587.6, 6598.21, 6587.6, 6594.27],
        // },
        // {
        //   x: "5:09",
        //   y: [6596.44, 6601, 6590, 6596.55],
        // },
        // {
        //   x: "6:01",
        //   y: [6598.91, 6605, 6596.61, 6600.02],
        // },
        // {
        //   x: "6:02",
        //   y: [6600.55, 6605, 6589.14, 6593.01],
        // },
        // {
        //   x: "6:04",
        //   y: [6593.15, 6605, 6592, 6603.06],
        // },
        // {
        //   x: "6:05",
        //   y: [6603.07, 6604.5, 6599.09, 6603.89],
        // },
      ],
    },
  ];
  const sampleDataDates = [
    { x: new Date(2016, 6, 1), open: 5, close: 10, high: 15, low: 0 },
    { x: new Date(2016, 6, 2), open: 10, close: 15, high: 20, low: 5 },
    { x: new Date(2016, 6, 3), open: 15, close: 20, high: 22, low: 10 },
    { x: new Date(2016, 6, 4), open: 20, close: 10, high: 25, low: 7 },
    { x: new Date(2016, 6, 5), open: 10, close: 8, high: 15, low: 5 },
  ];
  return (
    <div className="pd_each_market pd_currency_page">
      <div className="main_page">
        <BackToMarket />

        <div className="abount_currency">
          <div className="left">
            <p className="rank">
              Ranking: <span>#1</span>{" "}
            </p>
            <div className="flag_explain">
              <img src={england} alt="england" />
              <p>
                <span className="currency_code">USD</span>
                <span className="currency_name">Dollar Backed Securities</span>
              </p>
              <button className="track">
                {" "}
                <img src={track} alt="" /> Track
              </button>
            </div>
            <div className="trend">
              <p className="price">Yield(pa)</p>
              <div className="line">
                <img src={line} alt="line" />
                <p>2.0</p>
              </div>
              <div className="variation">
                <p className="title">Change(%)</p>
                <div className="growth red">
                  <img src={redArr} alt="red" />
                  <p>-2.5</p>
                </div>
              </div>
              <div className="variation">
                <p>Change (bps)</p>
                <div className="growth green">
                  <img src={greenArr} alt="green" />
                  <p>-2.5</p>
                </div>
              </div>
              <div className="riskRating">
                <p className="risktext">Risk Rating</p>
                <div className="risk">
                  <p>Low</p>
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Link to={"/invest"} className="invest">
              Invest in USD
            </Link>
          </div>
        </div>

        <div className="converting">
          <p className="first_text">Quick Conversion</p>

          <div className="convert_box">
            <div className="each_convert">
              <div className="left">
                <p className="changeText">Tender</p>
                <p className="number">20</p>
              </div>
              <div className="right">
                {/* <CurrencyDrop
                  handleCurrency={(val) => {
                    setTender(val);
                  }}
                  defaultCurrency={{ flag: nig, code: "NGN" }}
                /> */}
                <ConvertCurrencyDropDown handleCurrency={setTender} />
              </div>
            </div>
            <img src={convertIcon} alt="convert" className="midImg" />
            <div className="each_convert">
              <div className="left">
                <p className="changeText">Tender</p>
                <p className="number">20</p>
              </div>
              <div className="right">
                {/* <CurrencyDrop
                  handleCurrency={(val) => {
                    setTender(val);
                  }}
                  defaultCurrency={{ flag: nig, code: "NGN" }}
                /> */}
                <ConvertCurrencyDropDown handleCurrency={setTender} />
              </div>
            </div>
          </div>
        </div>
        <div className="conv_rating">
          <img src={coverconvert} alt="cover" />
          <p className="normal">Conversion Rate</p>
          <img src={union} alt="union" />
          <p className="dollar">$1 = ₦569</p>
        </div>
        <p className="date">Conversion rate as at 10th January 2021, 10:00AM</p>

        <div className="flex_container">
          <div className="chart_box">
            <p className="chart_head">Currency Performance</p>
            <div className="chart_flex">
              <div className="left">
                <img src={indicate} alt="indicate" />
                <p>Data is shown per minute performance</p>
              </div>
              <div className="right">
                <div className="day_filter">
                  {dayList.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setDay(item);
                        }}
                        className={`each ${item === day ? "active" : ""}`}
                      >
                        <p>{item}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="day_filter">
                  {chartList.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setChart(item);
                        }}
                        className={`each ${item === chart ? "active" : ""}`}
                      >
                        {/* <img
                          src={item === "candle" ? candle : trend}
                          alt="chart"
                        /> */}
                        {item === "candle" ? (
                          <Candle className="chart_toggle" />
                        ) : (
                          <Trend className="chart_toggle" />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="main_chart">
              {chart === "candle" ? (
                <Chart
                  options={optionsCandle}
                  series={seriesCandle}
                  type="candlestick"
                  height={"350"}
                />
              ) : (
                // <VictoryChart
                //   theme={VictoryTheme.material}
                //   domainPadding={{ x: 25 }}
                //   scale={{ x: "time" }}
                //   style={{
                //     grid: { stroke: "grey" },
                //   }}
                // >
                //   <VictoryAxis
                //     tickFormat={(t) => `${moment(t).format("LT")}`}
                //   />
                //   <VictoryAxis dependentAxis />
                //   <VictoryCandlestick
                //     candleColors={{ positive: "#14FF00", negative: "#FF0000" }}
                //     data={sampleDataDates}
                //   />
                // </VictoryChart>
                <Chart
                  options={options}
                  series={series}
                  type="line"
                  height={"350"}
                />
              )}
            </div>
          </div>
          <div className="topCountries">
            <p className="first_text">Live Prices</p>
            {[1, 2, 3, 4, 5, 6, 7].map((item) => {
              return (
                <EachTopCountry
                  isMarked={item % 2 === 0 ? true : false}
                  rate={item % 2 === 0 ? "up" : "down"}
                  rateNo={2.5}
                  name={"British Pound"}
                  code={"GBP"}
                  flag={england}
                />
              );
            })}
          </div>
        </div>

        <div className="information_container">
          <div className="top_side">
            <p className="first_text">Currency Information</p>
            <div className="info_flex">
              <div className="each_info">
                <p className="top">Previous Close (%)</p>
                <div className="bottom">
                  <p className="text">21</p>
                </div>
              </div>
              <div className="each_info">
                <p className="top">Open Price (%)</p>
                <div className="bottom">
                  <p className="text">21</p>
                </div>
              </div>
              <div className="each_info">
                <p className="top">Yield(pa)</p>
                <div className="bottom">
                  <div className="line">
                    <img src={line} alt="line" />
                    <p>2.0</p>
                  </div>
                </div>
              </div>
              <div className="each_info">
                <p className="top">Risk Rating</p>
                <div className="bottom">
                  <div className="risk">
                    <p>Low</p>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
              <div className="each_info">
                <p className="top">Change(%)</p>
                <div className="bottom">
                  <div className="growth red">
                    <img src={redArr} alt="red" />
                    <p>-2.5</p>
                  </div>
                </div>
              </div>
              <div className="each_info">
                <p className="top">YTD Return</p>
                <div className="bottom">
                  <div className="growth green">
                    <img src={greenArr} alt="green" />
                    <p>-2.5</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_side">
            <p className="story">
              The United States dollar (symbol: $; code: USD; also abbreviated
              US$ or U.S. Dollar, to distinguish it from other
              dollar-denominated currencies; referred to as the dollar, U.S.
              dollar, American dollar, or colloquially buck) is the official
              currency of the United States and its territories. The Coinage Act
              of 1792 introduced the U.S. dollar at par with the Spanish silver
              dollar, divided it into 100 cents, and authorized the minting of
              coins denominated in dollars and cents. U.S. banknotes are issued
              in the form of Federal Reserve Notes, popularly called greenbacks
              due to their historically predominantly green color.
            </p>
            <a href="/">Visit Website</a>
          </div>
        </div>
        <div className="news_container">
          <div className="news_flex_grid">
            <p className="first_text">Related News</p>
            {[1, 2, 3].map((item) => {
              return (
                <EachNewsGrid
                  key={item}
                  toolImg={toolImg}
                  banner={banner}
                  date={"JANUARY 10"}
                  title={
                    "Dollar hits new two and a half year low after month of losses"
                  }
                  news={
                    "On the last day of the month, the dollar was set for its biggest monthly loss against a basket of currencies ...."
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EachMarket;
