import { ComponentLoader } from "components/ComponentLoader";
import { useAppData } from "default/AppDataProvider";
import { Currency, WalletWithTransactions } from "lib/api/apiTypes";
import { useWalletQuery } from "pages/Dashboard/BankICE/queries";
import { PropsWithChildren, createContext, useContext } from "react";
import { useParams } from "react-router-dom";

type WalletContextValue = {
	wallet: WalletWithTransactions | null,
	walletCurrency: Currency | null,
}
const WalletContext = createContext<WalletContextValue>({
	wallet: null,
	walletCurrency: null,
});
export const useWalletContext = () => useContext(WalletContext);

function Provider({ children, ...rest }: PropsWithChildren<WalletContextValue>) {
	return (
		<WalletContext.Provider value={rest}>
			{children}
		</WalletContext.Provider>
	);
}

export function WalletProvider({ children }: PropsWithChildren) {
	const { appInfo } = useAppData();
	const { walletId } = useParams();
	const walletQuery = useWalletQuery(walletId!);

	const componentPropsFn = (wallet: WalletWithTransactions) => {
		const walletCurrency = appInfo.supportedCurrencies.find(item => item.code === wallet.currency)!;
		return { children, wallet, walletCurrency };
	};

	return (
		<ComponentLoader
			queries={[walletQuery]}
			component={Provider}
			componentPropsFn={componentPropsFn}
		/>
	);
}
