import React from "react";
import support from "../../assets/drawables/support.svg";
import "./style.scss";
import cn from "classnames";

type SupportBtnProps = React.HTMLProps<HTMLButtonElement>;

const SupportBtn = (props: SupportBtnProps) => {
  return (
    <button className={cn('support', props.className)}>
      <img src={support} alt="support" />
      Support
    </button>
  );
};

export default SupportBtn;
