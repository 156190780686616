import "./style.scss";
import arr from "assets/drawables/arrowblue.svg";
import send from "assets/drawables/send.svg";
import addimg from "assets/drawables/addimg.svg";
import { Box } from "@mui/material";
import { WalletTransactionMinimal, WalletTransactionType } from "lib/api/apiTypes";
import cn from "classnames";
import { formatAmount } from "lib/utils";
import { Link } from "react-router-dom";

/** @todo Use proper images for transaction types */
const tranxTypeImages: Record<WalletTransactionType, string> = {
	transfer: send,
	conversion: addimg,
	yield: addimg,
	amortisation: send,
	topup: addimg,
};

const tranxStatusText: Record<WalletTransactionMinimal['status'], string> = {
	pending: 'Pending',
	'in-progress': 'In progress',
	success: 'Succesful',
	failure: 'Failed',
};

type EachHistoryProps = {
	tranx: WalletTransactionMinimal,
}
const EachHistory = ({ tranx }: EachHistoryProps) => {
	const balanceAfter = tranx.balanceBefore + tranx.amount;

	return (
		<div className="pd_each_history">
			<div className="stat_part">
				<div className="cover_box">
					<img src={tranxTypeImages[tranx.type]} alt="stat" />
				</div>
				<p>{tranx.description}</p>
			</div>
			<Box display="flex" alignItems="center">
				<button
					className={cn(
						'stat_btn',
						{[tranx.status]: tranx.status !== 'in-progress'}
					)}
				>
					{tranxStatusText[tranx.status]}
				</button>

				<Box marginRight={5}>
					<p className="amount bold">{formatAmount(tranx.amount, tranx.currency)}</p>
					<p className="balance">{formatAmount(balanceAfter, tranx.currency)}</p>
				</Box>
				<Link to={`/dashboard/bank/${tranx.walletId}/transaction/${tranx.id}`}>
					<Box paddingX={0.5} paddingY={1}>
						<img src={arr} alt="arr" />
					</Box>
				</Link>
			</Box>
		</div>
	);
};

export default EachHistory;
