import "./style.scss";

type EachAccountProps = {
  amount: string | number,
  flag: string,
  currency: string,
}
const EachAccount = ({ flag, currency, amount }: EachAccountProps) => {
  return (
    <div className="pd_each_account">
      <div className="top_flex">
        <p>{currency}</p>
        <img src={flag} alt="flag" className="flag" />
      </div>
      <p className="amount">{amount}</p>
    </div>
  );
};

export default EachAccount;
