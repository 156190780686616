import { useMemo, useState } from "react";

import searchIcon from "../../assets/drawables/search.svg";
import EachLocation from "./EachLocation";
import down from "../../assets/drawables/down.svg";
import { Currency } from "../../lib/api/apiTypes";
import cn from "classnames";

type ConvertCurrencyDropDownProps = {
  currencies: Currency[],
  value: string | null,
  onChange: (currencyCode: string) => void,
}
export default function SelectCurrencyIce({ currencies, onChange, value }: ConvertCurrencyDropDownProps) {
  const [show, setShow] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const currencyObject = currencies.find(item => item.code === value);

  const onCurrencySelect = (currency: Currency) => {
    onChange(currency.code);
    setShow(false);
    setSearchValue('');
  };

  const searchResult = useMemo(() => {
    if (!searchValue) return currencies;

    // Match currency name and code
    return currencies.filter(curr => (
      [curr.name, curr.code].some(val => val.toLowerCase().includes(searchValue.toLowerCase()))
    ));
  }, [searchValue, currencies]);

  return (
    <div className="pd_convert_flag_drop_down ice">
      <div
        onClick={() => setShow(state => !state)}
        className="showCurrency"
      >
        {currencyObject && (
          <>
            <img className="smallFlag" src={currencyObject.flag} alt="" />
            <p className="name">{currencyObject.code}</p>
            <p className="currency">{currencyObject.name}</p>
          </>
        )}
        <img src={down} alt="down" className="arrdown" />
      </div>

      <div className={cn('drop_down', { show })}>
        <div className="drop_box">
          <img src={searchIcon} alt="search" className="search" />
          <input
            onClick={() => setShow(true)}
            name="currency"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search for currency"
            type="text"
          />
        </div>
        <div className="overflow">
          <div className="all_country">
            <div className="location_list">
              {!searchResult.length ? (
                <p className="empty">Empty Search</p>
              ) : (
                searchResult.map((item) => (
                  <EachLocation
                    key={item.code}
                    onClick={() => onCurrencySelect(item)}
                    code={item.code}
                    flag={item.flag}
                    name={item.name}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
