import { Link } from "react-router-dom";
import { useState } from "react";
import eye from "assets/drawables/eye.svg";
import addimg from "assets/drawables/addimg.svg";
import slant from "assets/drawables/slant.svg";
import "./style.scss";
import { formatAmount } from "lib/utils";
import { useWalletContext } from "./WalletProvider";

export function BalanceBox() {
	const { wallet, walletCurrency } = useWalletContext();
	const [hide, setHide] = useState(false);

	return (
		<div className="balance-box">
			<div className="left_box">
				<img className="flag" src={walletCurrency!.flag} alt="eng" />
				<div className="available_box">
					<div className="top_side">
						<p>Available Balance</p>
						<img
							onClick={() => setHide(value => !value)}
							src={eye}
							alt="eye"
						/>
					</div>
					<p className="money bold">
						{hide ? "*******" : formatAmount(wallet!.balance, wallet!.currency)}
					</p>
				</div>
			</div>
			<div className="right_box">
				<Link to={`/dashboard/bank/${wallet!.id}/topup`} className="each_btn">
					<img src={addimg} alt="add" />
					Lodge Money
				</Link>
				<Link to="/send" className="each_btn">
					<img src={slant} alt="add" />
					Send Money
				</Link>
				<button className="each_btn">
					<img src={slant} alt="add" />
					Exchange
				</button>
				<button className="each_btn">
					<img src={slant} alt="add" />
					Download Statement
				</button>
				<button className="each_btn">
					<img src={slant} alt="add" />
					Account Details
				</button>
				<button className="each_btn">
					<img src={slant} alt="add" />
					Freeze Card
				</button>
			</div>
		</div>
	);
}
