import { useRef, useState } from "react";
import convert from "assets/drawables/convert2.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import "./style.scss";
import pro from "assets/drawables/pro.svg";
import ech from "assets/drawables/ech.svg";
import union from "assets/drawables/Union2.svg";
import arr from "assets/drawables/arr.svg";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import ConvertCurrencyDropDown from "components/InputFields/convertCurrencyDropDown";
import "swiper/css";
import "swiper/css/bundle";
import Slider from "react-slick";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import heroimg from "assets/drawables/heroimg.png";
// import bg2 from "assets/drawables/bg1.jpg";
// import bg3 from "assets/drawables/bg3.jpg";
import { useAppData } from "default/AppDataProvider";

const Header = () => {
  const { appInfo } = useAppData();
  const currencies = appInfo.supportedCurrencies;

  const [tender, setTender] = useState(currencies[0].code);
  const [buy, setBuy] = useState(currencies[1].code);
  const [autoPlayOn, setAutoPlayOn] = useState(true);
  const sliderRef = useRef<Slider>(null);
  const [page, setPage] = useState({ activeSlide2: 1 });

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,

    speed: 500,
    /*beforeChange: (current, next) =>
      setPage({ oldSlide: current, activeSlide: next }),*/
    afterChange: (current: number) => setPage({ activeSlide2: current + 1 }),
  };

  return (
    <>
      <div className="header">
        <div className="empty_bg bg1">
          <div className="cover_slick_img">
            <img src={heroimg} alt="hero" />
          </div>
        </div>

        <Slider ref={sliderRef} {...settings}>
          <div className="cover_heading">
            <div className="header-text">
              <h2 id="header-text-main">
                Make Money, <br /> &nbsp;&nbsp; Send Money
              </h2>
            </div>
          </div>
          <div className="cover_heading">
            <div className="header-text">
              <h2 id="header-text-main">
                Make Cash, <br /> &nbsp;&nbsp; Send Money
              </h2>
            </div>
          </div>
          <div className="cover_heading">
            <div className="header-text">
              <h2 id="header-text-main">
                Make Money, <br /> &nbsp;&nbsp; Send Money
              </h2>
            </div>
          </div>
        </Slider>

        <div
          onClick={() => {
            setAutoPlayOn(!autoPlayOn);
            if (autoPlayOn) {
              sliderRef.current?.slickPause();
            } else {
              sliderRef.current?.slickPlay();
            }

          }}
          className="show_page"
        >
          <div className="pause_cover">
            {autoPlayOn ? <PauseIcon /> : <PlayArrowIcon />}
          </div>
          <p>{page.activeSlide2}/3</p>
        </div>
        <div className="header-form">
          <div className="convBox">
            <p className="title">Send Money</p>
            <div className="changeBox">
              <div className="conv">
                <img src={convert} alt="convert" />
              </div>
              <div className="top hard">
                <div className="left">
                  <p className="changeText">Tender</p>
                  <p className="number">20</p>
                </div>
                <div className="right">
                  <ConvertCurrencyDropDown
                    value={tender}
                    onChange={setTender}
                    currencies={currencies}
                  />
                </div>
              </div>
              <div className="top">
                <div className="left">
                  <p className="changeText">Buy</p>
                  <p className="number">20</p>
                </div>
                <div className="right">
                  <ConvertCurrencyDropDown
                    value={buy}
                    onChange={setBuy}
                    currencies={currencies}
                  />
                </div>
              </div>
            </div>
            <div className="pro">
              <div className="eachPro">
                <div className="left">
                  <img src={pro} alt="pro" />
                  <p>Processing Fee</p>
                </div>
                <div className="right">
                  <p>$20</p>
                </div>
              </div>
              <div className="eachPro">
                <div className="left">
                  <img src={ech} alt="pro" />
                  <p>Exchange Rate</p>
                </div>
                <div className="right">
                  <img src={union} alt="union" />
                  <p>$20</p>
                </div>
              </div>
              <div className="eachPro">
                <div className="left">
                  <img src={arr} alt="pro" />
                  <p>Arrival time</p>
                </div>
                <div className="right">
                  <p className="blue">⌛ ️20 mins</p>
                </div>
              </div>
            </div>
            <SubmitBtn className="green" btnText="Get Started" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
