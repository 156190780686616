import { useQuery } from "@tanstack/react-query";
import { getReferralData } from "lib/api";

export const ReferralQueryKeys = {
	summary: ['referral', 'summary'] as const,
};

export function useReferralSummaryQuery() {
	return useQuery({
		queryKey: ReferralQueryKeys.summary,
		queryFn: getReferralData,
	});
}
