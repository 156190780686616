import React, { useMemo } from "react";
import './style.scss';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import EachAccount from "components/EachAccount";
import { useAppData } from "../../../../default/AppDataProvider";
import { formatAmount, mapCurrencies } from "../../../../lib/utils";
import { Link } from "react-router-dom";

export function Accounts() {
	const { appInfo, user } = useAppData();

	const currencyMap = useMemo(
		() => mapCurrencies(appInfo.supportedCurrencies),
		[appInfo.supportedCurrencies],
	);

	const wallets = user!.wallets.slice(0, 2);

	return (
		<div className="account_side">
			<div className="cover_first_text">
				<p className="first_text">YOUR ACCOUNTS</p>
			</div>

			<div className="account_flex">
				{wallets.map((wallet) => {
					const currObj = currencyMap[wallet.currency];
					return (
						<EachAccount
							key={wallet.id}
							currency={currObj.name}
							flag={currObj.flag}
							amount={formatAmount(wallet.balance, wallet.currency)}
						/>
					);
				})}
				{user!.wallets.length > wallets.length ? (
					<Link to="/dashboard/bank" className="create_box">
						<p>View all</p>
						<ArrowCircleRightOutlinedIcon />
					</Link>
				) : (
					<Link to="/bank/create_account" className="create_box">
						<p>+ Create Account</p>
					</Link>
				)}
			</div>
		</div>
	);
}
