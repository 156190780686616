import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../../lib/api";

export const UserQueryKeys = {
	profile: ['profile'] as const,
};

/** @deprecated Use AppDataContext instead */
export function useProfile() {
	return useQuery({
		queryKey: UserQueryKeys.profile,
		queryFn: getProfile,
		staleTime: 1 * 60 * 60 * 1000, // 1 hour
	});
}
