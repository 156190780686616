import { useEffect } from "react";
import { Alert, Checkbox } from "@mui/material";
import InputField from "components/InputFields";
import InputPhone from "components/InputFields/inputPhone";
import SelectField from "components/InputFields/SelectField";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { FormProvider, useForm } from "react-hook-form";
import "./style.scss";
import { useAppData } from "default/AppDataProvider";
import { InferType, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { createTransferRecipient } from "lib/api";
import { CreateTransferRecipientDto } from "lib/api/requestTypes";
import { useSendMoneyContext } from "../../SendMoneyProvider";

const recipientCreateSchema = object({
  name: string().required(),
  email: string().email().required(),
  phone: string(),
  country: string().required(),
  bank: string().required(),
  accountNumber: string().required(),
});

/** @todo Get real banks from backend */
const bankSelectOptions = [
  { label: "Deposit to Access USD account", value: "usd" },
  { label: "Deposit to Access USD account1", value: "usd1" },
  { label: "Deposit to Access USD account2", value: "usd2" },
  { label: "Deposit to Access USD account3", value: "usd3" },
  { label: "Deposit to Access USD account4", value: "usd4" },
  { label: "Deposit to Access USD account5", value: "usd5" },
];

type RecipientCreateSchema = InferType<typeof recipientCreateSchema>;

export const RecipientForm = () => {
  const { appInfo } = useAppData();
  const { transfer, updateTransfer, gotoNextStep } = useSendMoneyContext();

  const mutation = useMutation({
    mutationFn: createTransferRecipient,
  });
  const methods = useForm({ resolver: yupResolver(recipientCreateSchema) });

  /** @todo Remove form error logging */
  useEffect(() => {
    if (methods.formState.errors) {
      // console.log('errors', methods.formState.errors);
    }
  }, [methods.formState.errors]);

  const onSubmit = async (val: RecipientCreateSchema) => {
    console.log('Calling onsubmit...');
    const { bank: bankCode, ...rest } = val;
    const bank = bankSelectOptions.find(item => item.value === bankCode);
    console.log({ bank, transfer });
    if (!bank) return;
    if (!transfer.toCurrency) return;

    const data: CreateTransferRecipientDto = {
      ...rest,
      bankName: bank.label,
      bankCode: bank.value,
      type: 'external-bank-account',
      currency: transfer.toCurrency,
    };

    const recipient = await mutation.mutateAsync(data);
    updateTransfer({ recipient });

    gotoNextStep();
  };

  const { supportedCountries: countries } = appInfo;
  const countryOptions = countries.map(country => ({ label: country.name, value: country.code }));

  return (
    <div className="pd_recipient_form">

      {mutation.isError && mutation.error instanceof Error && (
        <Alert autoFocus severity="error">{mutation.error.message}</Alert>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputField name="name" id="name" label="Name" required />

          <InputPhone countries={countries} isMsg label='Enter mobile Number' />

          <InputField
            type="email"
            name="email"
            placeholder="recipient@gmail.com"
            id="email"
            label="Email Address"
            required
          />

          <SelectField
            name='country'
            label='Country'
            selectOption={countryOptions}
            required
          />

          <SelectField
            label="Bank"
            name="bank"
            selectOption={bankSelectOptions}
            required
          />
          <InputField
            type="number"
            name="accountNumber"
            placeholder=""
            id="accountNumber"
            label="Account Number"
            required
          />
          <div className="attestation_side">
            <p className="title">Attestation</p>
            <p className="desc">
              We can only transfer payments to USD bank accounts in Nigeria. If
              the recipient does not have a USD bank account, the bank may
              automatically create one for them. By selecting 'Yes, I agree',
              you consent to pay the amount to a USD bank account created by the
              bank for the recipient. This USD bank account is associated with
              the original NGN bank account selected for the transfer.
            </p>
          </div>
          <div className="check_container">
            <Checkbox defaultChecked color="secondary" />
            <p>I agree to send the payment to my recipient's USD account.</p>
          </div>
          <SubmitBtn btnText='Continue' className="green" isLoading={mutation.isPending} />
        </form>
      </FormProvider>
    </div>
  );
};
