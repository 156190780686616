import React from "react";
import './style.scss';
import { Link } from "react-router-dom";
import england from "assets/drawables/england.svg";
import EachAsset from "components/EachAsset";

export const Assets = () => {
	return (
		<div className="account_side">
			<div className="cover_first_text">
				<p className="first_text">YOUR ASSETS</p>
				<Link to="/">View All</Link>
			</div>

			<div className="cover_flex overflow">
				<div className="account_flex over">
					<EachAsset
						currency='USD'
						flag={england}
						amount='$7.356,67'
						isRed={true}
						percent='5.2'
					/>
					<EachAsset
						currency='USD'
						flag={england}
						amount='$7.356,67'
						isRed={false}
						percent='5.2'
					/>
					<EachAsset
						currency='USD'
						flag={england}
						amount='$7.356,67'
						isRed={true}
						percent='5.2'
					/>
				</div>
			</div>
		</div>
	);
}
