import Box from "@mui/material/Box";
import React, { PropsWithChildren } from "react";

export function Centered({ children }: PropsWithChildren) {
	return (
		<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' minHeight='100vh'>
			{children}
		</Box>
	);
}
