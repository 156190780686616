import { useState } from "react";
import "./style.scss";
import mastercard from "../../../../assets/drawables/mastercard.svg";
import { Link } from "react-router-dom";
import EachBankCard from "./EachBankCard";
import { Grid } from "@mui/material";
import emptyaccount from "../../../../assets/drawables/emptyaccount.svg";
import Modal from "../../../../components/ModalPopUp/index";
import SlantModal from "../../../../components/ModalPopUp/slantModal";
import CreateCardModal from "./EachBankCard/CreateCardModal";
import SuccessModal from "./EachBankCard/SuccessModal";
const CardIce = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [list, setList] = useState([
    {
      number: "0000 0000 **** **00",
      name: "Ademola Julius",
      date: "15/07",
      img: mastercard,
    },
    {
      number: "0000 0000 **** **00",
      name: "Ademola Julius",
      date: "15/07",
      img: mastercard,
    },
  ]);
  return (
    <div className="pd_card_ice">
      {!list.length ? (
        <div
          onClick={() => {
            setOpenModal(true);
          }}
          className="create_box"
        >
          + Create New Card
        </div>
      ) : (
        ""
      )}
      {list.length ? (
        <Grid container spacing={4} mt={1}>
          {list.map((item, i) => {
            return (
              <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                <EachBankCard item={item} />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div
              onClick={() => {
                setOpenModal(true);
              }}
              className="create_box inList"
            >
              + Create New Card
            </div>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {!list.length ? (
        <div className="emptyaccount">
          <img src={emptyaccount} alt="" className="emp" />
          <p>You have no account</p>
        </div>
      ) : (
        ""
      )}

      <Modal
        title={"Select card Type"}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      >
        <CreateCardModal
          setOpenModal2={setOpenModal2}
          setOpenModal={setOpenModal}
        />
      </Modal>
      <SlantModal
        title={"Select card Type"}
        closeModal={() => setOpenModal2(false)}
        openModal={openModal2}
      >
        <SuccessModal setOpenModal2={setOpenModal2} />
      </SlantModal>
    </div>
  );
};

export default CardIce;
