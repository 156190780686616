import React, { PropsWithChildren, createContext, useContext } from "react";
import { AppInfo } from "../lib/api/requestTypes";
import { OnboardingQueryKeys } from "./queries";
import { Centered } from "../components/Centered";
import { ComponentLoader, ErrorState } from "../components/ComponentLoader";
import { CircularLoading } from "../components/CircularLoading";
import { getAppInfo, getProfile, hasAuth } from "../lib/api";
import { UseQueryOptions, useQueries, useQueryClient } from "@tanstack/react-query";
import { UserQueryKeys } from "../pages/Authentication/queries";
import { User } from "../lib/api/apiTypes";
import { splitFullName } from "../lib/utils";

type AppDataValue = {
	appInfo: AppInfo | null,
	user: User | null,
}
const AppDataContext = createContext<AppDataValue>({ appInfo: null, user: null });

export function useAppData() {
	const { appInfo, user } = useContext(AppDataContext);
	const queryClient = useQueryClient();

	return {
		appInfo: appInfo!,
		user: user || queryClient.getQueryData(UserQueryKeys.profile) || null,
	};
}

export function useProfileName() {
	const { user } = useAppData();
	return splitFullName(user?.name ?? '');
}

function Provider({ appInfo, children, user }: PropsWithChildren<AppDataValue>) {
	return (
		<AppDataContext.Provider value={{ appInfo, user }}>
			{children}
		</AppDataContext.Provider>
	);
}

export function AppDataProvider({ children }: PropsWithChildren) {
	const queriesParams: UseQueryOptions[] = [
		// App info
		{
			queryKey: OnboardingQueryKeys.appInfo,
			queryFn: getAppInfo,
			staleTime: 1 * 60 * 60 * 1000, // 1 hour
		},
		// Current user
		{
			queryKey: UserQueryKeys.profile,
			queryFn: getProfile,
			staleTime: 1 * 60 * 60 * 1000, // 1 hour
			enabled: hasAuth(),
		}
	];
	const queries = useQueries({ queries: queriesParams });

	const componentPropsFn = (appInfo: AppInfo, user?: User) => ({
		appInfo,
		user: user ?? null,
		children,
	});

	return (
		<ComponentLoader
			queries={queries}
			componentPropsFn={componentPropsFn}
			component={Provider}
			renderLoading={() => (
				<Centered>
					<CircularLoading />
				</Centered>
			)}
			renderError={() => (
				<Centered>
					<ErrorState message="Something went wrong" />
				</Centered>
			)}
		/>
	);
}
