import "./style.scss";
import SendMoneyHeader from "../../widget/SendMoneyHeader";
import { useSendMoneyContext } from "../../SendMoneyProvider";
import { CardPayment } from "components/CardPayment";

export const PayWithCard = () => {
  const { payWithCardMutation, transfer } = useSendMoneyContext();

  return (
    <div className="pd_set_card">
      <SendMoneyHeader active="payment" />
      <div className="main_aspect">
        <p className="first_text">Enter your card details</p>
        <CardPayment
          amount={transfer.amountPayable!}
          changePaymentMethodLink="/send#payment"
          currency={transfer.fromCurrency!}
          mutation={payWithCardMutation!}
          paymentSuccessMsg="Your transfer has been initiated. $1,000 will be sent to Ademola"
        />
      </div>
    </div>
  );
};
