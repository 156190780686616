import React from "react";
import NavbarDashboard from "components/NavbarDashboard";
import Sidebar from "components/Sidebar";
import "./style.scss";
import { Outlet, useLocation } from "react-router-dom";

const DashboardLayout = () => {
  const { pathname } = useLocation();
  const header = pathname.split('/')[2] ?? 'home';

  return (
    <div className="dashboard_layout">
      <Sidebar />
      <div className="main_side">
        <NavbarDashboard showThemeToggle header={header} />
        <div className="screen_content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
