import { useQuery } from "@tanstack/react-query";
import { getAppInfo } from "../lib/api";

export const OnboardingQueryKeys = {
	appInfo: ['appInfo'] as const,
};

export const appInfoFetchParams = {
	queryKey: OnboardingQueryKeys.appInfo,
	queryFn: getAppInfo,
	staleTime: 1 * 60 * 60 * 1000, // 1 hour
};

/** @deprecated Use AppDataContext instead */
export function useAppInfo() {
	return useQuery(appInfoFetchParams);
}