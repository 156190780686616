import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { useState } from "react";
import OtpInput from "react-otp-input";
import "./style.scss";
const ChangePin = ({ setOpenModal2 }) => {
  const [otp, setOtp] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const handleChange = (otp) => {
    setOtp(otp);
  };
  const handleChange1 = (otp) => {
    setOtp(otp);
  };
  const handleChange2 = (otp) => {
    setOtp(otp);
  };
  return (
    <div className="pd_enter_pin_box changepin">
      <p className="small_text">
        Set a 4 digit card PIN. Use your card PIN to make transactions
      </p>
      <div className="cover_all_otp">
        <div className="otp_wrap">
          <label>Enter current PIN</label>

          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            type={"password"}
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <div className="otp_wrap">
          <label>Enter new PIN</label>

          <OtpInput
            value={otp1}
            onChange={handleChange1}
            numInputs={4}
            type={"password"}
            separator={<span> </span>}
          />
        </div>
        <div className="otp_wrap">
          <label>Confirm new PIN</label>

          <OtpInput
            value={otp2}
            onChange={handleChange2}
            numInputs={4}
            type={"password"}
            separator={<span> </span>}
          />
        </div>
      </div>
      <SubmitBtn
        type="button"
        disable={otp ? true : false}
        onClick={() => {
          setOpenModal2(false);
        }}
        className="btn-primary blue"
        btnText={"Change Pin"}
      />
    </div>
  );
};

export default ChangePin;
