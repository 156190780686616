import React, { useEffect, useState } from "react";
import dropIcon from "../../assets/drawables/dropIcon.svg";
import searchIcon from "../../assets/drawables/search.svg";
import EachLocation from "./EachLocation";
import { useFormContext } from "react-hook-form";
import { Country } from "../../lib/api/apiTypes";
import cn from "classnames";

type CustomFlagDropDownProps = {
  name?: string,
  label?: string,
  disabled?: boolean
  countries: Country[]
};

const CustomFlagDropDown = ({
  name = 'country',
  label = 'Where do you reside?',
  disabled = false,
  countries,
}: CustomFlagDropDownProps) => {
  const { setValue, watch } = useFormContext();

  const [show, setShow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState(countries);

  const formValue = watch(name);
  useEffect(() => {
    const country = countries.find(item => item.code === formValue);
    if (country) setSearchValue(country.name);
  }, [countries, formValue]);
  
  const handleCountrySelect = (location: Country) => {
    setValue(name, location.code);
    setShow(false);
    setSearchValue(location.name);
    setSearchResult(countries);
  };

  const handleSearchInput = (value: string) => {
    setSearchValue(value);
    setShow(true);
    setSearchResult(() => {
      if (!value) return countries;
      return countries.filter(
        item => item.name.toLowerCase().includes(value.toLowerCase()),
      );
    });
  };

  /** @todo Get actual location country */
  const locationCountry = countries[0];

  return (
    <div className="pd_custom_flag_drop_down">
      <div className="cover_all">
        <label htmlFor="">{label}</label>
        <div className="drop_box">
          <img src={searchIcon} alt="search" className="search" />
          <input
            onClick={() => setShow(true)}
            name={name}
            value={searchValue}
            onChange={(e) => handleSearchInput(e.target.value)}
            placeholder="Select a country "
            type="text"
            disabled={disabled}
          />
          <img
            onClick={() => !disabled && setShow(show => !show)}
            src={dropIcon}
            alt="dropIcon"
            className="dropIcon"
          />
        </div>
      </div>
      <div className={cn('drop_down', { show })}>
        <div className="overflow">
          {locationCountry && (
            <div className="own_country">
              <p className="title">Based on your location</p>

              <EachLocation
                onClick={() => handleCountrySelect(locationCountry)}
                code={locationCountry.code}
                flag={locationCountry.flag}
                name={locationCountry.name}
              />
            </div>
          )}
          <div className="all_country">
            <p className="title">All Countries</p>
            <div className="location_list">
              {!searchResult.length ? (
                <p className="empty">Empty Search</p>
              ) : (
                searchResult.map((item) => {
                  return (
                    <EachLocation
                      onClick={() => handleCountrySelect(item)}
                      key={item.code}
                      code={item.code}
                      flag={item.flag}
                      name={item.name}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomFlagDropDown;
