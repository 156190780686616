import CryptoJS from "crypto-js";
import { Currency } from "./api/apiTypes";
import _ from "lodash";

/**
 * Returns a random integer between min and max, seeded daily with the given seed
 */
function getDailyRandomFloat(min: number, max: number, seed: string) {
	const dailySeed = `${seed}-${new Date().toISOString().slice(0, 10)}`;
	const hash = CryptoJS.SHA256(dailySeed).toString(CryptoJS.enc.Hex);
	const decimalValue = parseInt(hash, 16) / (Math.pow(2, 256) - 1);
  
  // Scale the decimal value to the desired range between min and max
  const scaledValue = min + decimalValue * (max - min);

  return scaledValue;
}

export function generateMarketAssets(currencies: Currency[]) {
	const defaultRateRanges = {
		AAA: [0.001, 0.003],
		AA: [0.003, 0.005],
		A: [0.005, 0.01],
		B: [0.01, 0.02],
		C: [0.02, 0.05],
		D: [0.05, 0.1],
	};
	
	const assets = currencies.map((currency) => {
		let { rating = 'A' } = currency;
		// Remove the + or - from the rating
		rating = rating.replace(/[+-]/g, '');
		if (!(rating in defaultRateRanges)) {
			rating = rating[0] in defaultRateRanges ? rating[0] : 'A';
		}

		const defaultRateRange = defaultRateRanges[rating as keyof typeof defaultRateRanges];

		const marketCap = getDailyRandomFloat(1e9, 1e12, `${currency.code}-marketCap`);
		const yieldPa = getDailyRandomFloat(0.01, 0.2, `${currency.code}-yieldPa`);
		const change = getDailyRandomFloat(-0.05, 0.1, `${currency.code}-change`);
		const defaultRate = getDailyRandomFloat(defaultRateRange[0], defaultRateRange[1], `${currency.code}-defaultRate`);
		const volume = getDailyRandomFloat(1e9, 1e12, `${currency.code}-volume`);
		const discount = getDailyRandomFloat(0.01, 0.1, `${currency.code}-discount`);

		return {
			currency,
			name: currency.name,
			marketCap,
			yieldPa,
			change,
			defaultRate,
			rating: currency.rating,
			volume,
			discount,
		};
	});

	return _.sortBy(assets, 'marketCap').reverse();
}
