import ListConversion from "components/ListConversion";
import TopNavbar from "components/navbar/top_navbar";
import PageDescription from "components/PageDescription";

import { MouseEventHandler, useMemo, useState } from "react";

import { ReactComponent as Star } from "../../assets/drawables/star.svg";
import { ReactComponent as StarSolid } from "../../assets/drawables/star_solid.svg";
import redArr from "../../assets/drawables/redArrow.svg";
import greenArr from "../../assets/drawables/greenArrow.svg";
import "./style.scss";
import Trend from "react-trend";
import { Box, Pagination, Typography } from "@mui/material";
import SearchComponent from "components/SearchComponent";
import cn from "classnames";
import { generateMarketAssets } from "lib/random";
import { useAppData } from "default/AppDataProvider";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";


const marketTypes = {
  primary: {
    name: 'Primary Markets',
  },
  secondary: {
    name: 'Secondary Markets',
  },
};
type MarketType = keyof typeof marketTypes;
type Asset = ReturnType<typeof generateMarketAssets>[0];

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
});

const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

const trendUpData = [0, 10, 5, 22, 3.6, 11];
const trendDownData = [11, 3.6, 22, 5, 10, 0];

const filters = ['watchlist', 'alphabetical', 'ratings', 'yield'] as const;
const transformers = {
  'alphabetical': {
    name: 'Alphabetical (A-Z)',
    transformer(assets: Asset[]) {
      return assets.slice().sort((a, b) => a.currency.code.localeCompare(b.currency.code));
    },
    next: 'alphabetical:z-a',
  },
  'alphabetical:z-a': {
    name: 'Alphabetical (Z-A)',
    transformer(assets: Asset[]) {
      return assets.slice().sort((a, b) => b.currency.code.localeCompare(a.currency.code));
    },
    next: null,
  },
  'ratings': {
    name: 'Ratings (Low - High)',
    transformer(assets: Asset[]) {
      return assets.slice().sort((a, b) => a.rating.localeCompare(b.rating));
    },
    next: 'ratings:9-0',
  },
  'ratings:9-0': {
    name: 'Ratings (High - Low)',
    transformer(assets: Asset[]) {
      return assets.slice().sort((a, b) => b.rating.localeCompare(a.rating));
    },
    next: null,
  },
  'watchlist': {
    name: 'Watchlist',
    transformer(assets: Asset[], watchlist: string[]) {
      return assets.filter((asset) => watchlist.includes(asset.currency.code));
    },
    next: null,
  },
  'yield': {
    name: 'Yield (Low - High)',
    transformer(assets: Asset[]) {
      return assets.slice().sort((a, b) => a.yieldPa - b.yieldPa);
    },
    next: 'yield:9-0',
  },
  'yield:9-0': {
    name: 'Yield (High - Low)',
    transformer(assets: Asset[]) {
      return assets.slice().sort((a, b) => b.yieldPa - a.yieldPa);
    },
    next: null,
  },
} as const;

type AssetRowProps = {
  marketType: MarketType,
  asset: ReturnType<typeof generateMarketAssets>[0],
  inWatchlist?: boolean,
  index: number,
  onStarClick?: () => void,
}
function AssetRow({
  asset,
  marketType,
  inWatchlist = false,
  index,
  onStarClick = () => { },
}: AssetRowProps) {
  const navigate = useNavigate();

  const assetCap = marketType === 'primary' ? asset.marketCap : asset.volume;
  const yieldPa = marketType === 'primary' ? asset.yieldPa : asset.discount;

  const changeClass = asset.change < 0 ? 'red' : 'green';

  const actionOnClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.stopPropagation();
  }

  const actions = marketType === 'primary' ? (
    <button className="invest" onClick={actionOnClick}>Invest</button>
  ) : (
    <Box display="flex" gap={2}>
      <button className="invest" onClick={actionOnClick}>Borrow</button>
      <button className="invest" onClick={actionOnClick}>Buy</button>
    </Box>
  );

  return (
    <tr className="asset-row" onClick={() => navigate(`/market/${asset.currency.code}`)}>
      <td>{inWatchlist ? <StarSolid onClick={onStarClick} /> : <Star onClick={onStarClick} />}</td>
      <td className="serial">{index}</td>
      <td>
        <Link to={`/market/${asset.currency.code}`}>
          <div className="country">
            <img src={asset.currency.flag} alt="flag" />
            <div className="textSide">
              <p className="top">{asset.currency.code}</p>
            </div>
          </div>
        </Link>
      </td>
      <td className="serial">{numberFormatter.format(assetCap)}</td>
      <td className="serial center">{percentFormatter.format(yieldPa)}</td>
      <td>
        <Box display="flex" gap={0.5}>
          <img src={asset.change < 0 ? redArr : greenArr} alt={changeClass} />
          <p className={cn('serial', changeClass)}>{percentFormatter.format(Math.abs(asset.change))}</p>
        </Box>
      </td>
      <td className="serial center">{percentFormatter.format(asset.defaultRate)}</td>
      <td className="center"><p>{asset.rating}</p></td>
      <td className="center"><p className="green">Active</p></td>
      <td className="center">
        <div className="trend">
          <Trend
            data={asset.change < 0 ? trendDownData : trendUpData}
            autoDraw
            autoDrawDuration={200}
            autoDrawEasing="ease-in"
            gradient={[asset.change < 0 ? "#F34018" : "#34E36F"]}
          />
        </div>
      </td>
      <td className="center">{actions}</td>
    </tr>
  );
}

export default function MarketPage() {
  const { appInfo } = useAppData();
  const assets = useMemo(() => generateMarketAssets(appInfo.supportedCurrencies), [appInfo.supportedCurrencies]);

  const [marketType, setMarketType] = useState<MarketType>('primary');
  const [watchlist, setWatchlist] = useState<string[]>([]);

  const totalMarketCap = assets.reduce((acc, asset) => acc + asset.marketCap, 0);
  const totalVolume = assets.reduce((acc, asset) => acc + asset.volume, 0);

  const marketTotal = marketType === 'primary'
    ? `Total Market Cap: $${numberFormatter.format(totalMarketCap)}`
    : `Total Volume: $${numberFormatter.format(totalVolume)}`;

  const [activeFilter, setActiveFilter] = useState<keyof typeof transformers | null>(null);

  const transformedAssets = useMemo(() => {
    if (!activeFilter) return assets;
    return transformers[activeFilter].transformer(assets, watchlist);
  }, [assets, activeFilter, watchlist]);

  const onFilterBtnClick = (type: typeof filters[number]) => {
    setActiveFilter((prev) => {
      if (!prev || !prev.startsWith(type)) return type;
      return transformers[prev].next;
    });
  };

  return (
    <div className="pd_market_page">
      <TopNavbar />
      <ListConversion />
      <div id="market_page_page_description_wrapper">
        <PageDescription
          title="Markets"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt u"
          date="Data as at 10th January 2021, 10:00AM"
        />
        <Typography variant="body1" color="primary">
          {marketTotal}
        </Typography>
      </div>
      <div className="cover_all">
        <div className="filterDiv">
          <div className="btn_cover">
            {Object.keys(marketTypes).map((key) => {
              return (
                <button
                  className={cn("filter_btn", { active: key === marketType })}
                  onClick={() => setMarketType(key as MarketType)}
                  key={key}
                >
                  {marketTypes[key as MarketType].name}
                </button>
              );
            })}
          </div>
          <SearchComponent />
        </div>

        <div className="btn_cover">
          {filters.map((key) => (
            <button
              className={cn("filter_btn", { active: activeFilter?.startsWith(key) })}
              onClick={() => onFilterBtnClick(key)}
              key={key}
            >
              {activeFilter?.startsWith(key) ? transformers[activeFilter].name : _.capitalize(key)}
            </button>
          ))}
        </div>

        {/* <div className="extraFilter">
          {status === "Alphabetical Order" &&
            alphabet.map((item) => {
              return (
                <div
                  onClick={() => {
                    setAlphabetStatus(item);
                  }}
                  className={`each_extra_filter ${
                    alphabetStatus.toLocaleLowerCase() ===
                    item.toLocaleLowerCase()
                      ? "active"
                      : ""
                  }`}
                >
                  {" "}
                  <p>{item}</p> <div className="line"></div>
                </div>
              );
            })}

          {status === "Region" &&
            region.map((item) => {
              return (
                <div
                  onClick={() => {
                    setRegionStatus(item);
                  }}
                  className={`each_extra_filter ${
                    regionStatus.toLocaleLowerCase() ===
                    item.toLocaleLowerCase()
                      ? "active"
                      : ""
                  }`}
                >
                  {" "}
                  <p>{item}</p> <div className="line"></div>
                </div>
              );
            })}

          {status === "Risk Category" &&
            risk.map((item) => {
              return (
                <div
                  onClick={() => {
                    setRiskStatus(item);
                  }}
                  className={`each_extra_filter ${
                    riskStatus.toLocaleLowerCase() === item.toLocaleLowerCase()
                      ? "active"
                      : ""
                  }`}
                >
                  {" "}
                  <p>{item}</p> <div className="line"></div>
                </div>
              );
            })}
          {status === "Yield" &&
            yieldStatus.map((item) => {
              return (
                <div
                  onClick={() => {
                    setYieldStat(item);
                  }}
                  className={`each_extra_filter ${
                    yieldStat.toLocaleLowerCase() === item.toLocaleLowerCase()
                      ? "active"
                      : ""
                  }`}
                >
                  {" "}
                  <p>{item}</p> <div className="line"></div>
                </div>
              );
            })}
        </div> */}

        <div className="marketTable">
          <div className="overflow">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>#</th>
                  <th>Asset</th>
                  <th>{marketType === 'primary' ? 'Market Cap' : 'Volume'}</th>
                  <th className="center">
                    {marketType === 'primary' ? 'Yield (pa)' : 'Discount'}
                  </th>
                  <th>Change</th>
                  <th className="center">Default Rate</th>
                  <th className="center">Ratings</th>
                  <th className="center">Status</th>
                  <th className="center">Trend (Last 30 days)</th>
                  <th className="center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {transformedAssets.map((asset, index) => (
                  <AssetRow
                    key={asset.currency.code}
                    asset={asset}
                    marketType={marketType}
                    index={index + 1}
                    inWatchlist={watchlist.includes(asset.currency.code)}
                    onStarClick={() => {
                      if (watchlist.includes(asset.currency.code)) {
                        setWatchlist(watchlist.filter((item) => item !== asset.currency.code));
                      } else {
                        setWatchlist([...watchlist, asset.currency.code]);
                      }
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-wrap">
            <Pagination
              color="primary"
              onChange={() => { }}
              count={5}
              shape="rounded"
            // components={{ previous: "Prev", next: "Next" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
