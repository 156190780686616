import "./style.scss";
import back from "../../../../../assets/drawables/back.svg";
import EachBankCard from "../EachBankCard";
import ListConversion from "components/ListConversion";
import mastercard from "../../../../../assets/drawables/mastercard.svg";
import { useEffect, useState } from "react";
import send from "../../../../../assets/drawables/send.svg";
import addimg from "../../../../../assets/drawables/addimg.svg";
import EachHistory from "../../EachHistory";
import Modal from "components/ModalPopUp";
import EnterPin from "./enterpin";
import ChangePin from "./changePassword";
import ActionCard from "./actioncard";
import { useNavigate } from "react-router-dom";
const AboutBankCard = () => {
  const card = {
    number: "0000 0000 **** **00",
    name: "Ademola Julius",
    date: "15/07",
    img: mastercard,
  };
  const [detail, setDetail] = useState(false);
  const [view, setView] = useState(false);
  const navigate = useNavigate();
  const activityList = [
    {
      status: "In progress",
      amount: "$534.23",
      balance: "$30,045.23",
      text: "Transfer to Ademola",
      img: send,
    },
    {
      status: "Successful",
      amount: "$534.23",
      balance: "$30,045.23",
      text: "Transfer to Ademola",
      img: send,
    },
    {
      status: "Failed",
      amount: "$534.23",
      balance: "$30,045.23",
      text: "Fund Wallet",
      img: addimg,
    },
    {
      status: "Successful",
      amount: "$534.23",
      balance: "$30,045.23",
      text: "Transfer to Ademola",
      img: send,
    },
  ];
  const [drop, setDrop] = useState(false);
  const [type, setType] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [actionType, setActionType] = useState("");
  useEffect(() => {
    if (actionType) {
      setOpenModal3(true);
    }
  }, [actionType]);
  return (
    <div className="pd_about_bank_card">
      <ListConversion />
      <div className="main_aspect">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="back_box"
        >
          <img src={back} alt="back" />
          <p>Back to ICE Bank</p>
        </div>
        <div className="cover_all_card">
          <div className="left_card">
            <p className="your">Your Card</p>
            <EachBankCard item={card} />
            <div className="action_flex">
              <p
                onClick={() => {
                  // setDetail(!detail);
                  setOpenModal(true);
                }}
                className="view"
              >
                View Details
              </p>
              <p
                onClick={() => {
                  setDrop(!drop);
                }}
                className="manage"
              >
                Manage Card
              </p>
              <div className={`drop_manage ${drop ? "show" : ""}`}>
                <button
                  onClick={() => {
                    setOpenModal2(true);
                  }}
                >
                  Change PIN
                </button>
                <button
                  onClick={() => {
                    setActionType("deactivate");
                  }}
                >
                  Deactivate Card
                </button>
                <button
                  onClick={() => {
                    setActionType("delete");
                  }}
                  className="red"
                >
                  Delete Card
                </button>
              </div>
            </div>
            {detail && (
              <div className="pd_card_detail">
                <div className="head_side">
                  <p className="bold">Card Details</p>
                </div>
                <div className="det_side">
                  <div className="each_det">
                    <p className="blue">Card Name</p>
                    <p className="black">Ademola Julius</p>
                  </div>
                  <div className="each_det">
                    <p className="blue">Card Number</p>
                    <p className="black">1234 5678 9028 2345</p>
                  </div>
                  <div className="each_det_flex">
                    <div className="each_det">
                      <p className="blue">CVV</p>
                      <p className="black">533</p>
                    </div>
                    <div className="each_det">
                      <p className="blue">Expiry Date</p>
                      <p className="black">22/22</p>
                    </div>
                    <div className="each_det">
                      <p className="blue">Zip Code</p>
                      <p className="black">12343</p>
                    </div>
                  </div>
                  <div className="each_det">
                    <p className="blue">Billing Address</p>
                    <p className="black">1234 5678 9028 2345</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="right_card">
            <div className="box_title">
              <p className="bold">Transaction History</p>
              <input type="date" />
            </div>
            <div className="full_history">
              <p className="day">Today</p>
              {activityList.map((item) => {
                return (
                  <EachHistory
                    setView={setView}
                    setType={setType}
                    item={item}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"Enter Pin"}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      >
        <EnterPin setDetail={setDetail} setOpenModal={setOpenModal} />
      </Modal>
      <Modal
        title={"Change card PIN"}
        closeModal={() => setOpenModal2(false)}
        openModal={openModal2}
      >
        <ChangePin setOpenModal2={setOpenModal2} />
      </Modal>
      <Modal
        title={actionType === "delete" ? "Delete card" : "Deactivate card"}
        isRed={actionType === "delete" ? true : false}
        closeModal={() => setOpenModal3(false)}
        openModal={openModal3}
      >
        <ActionCard type={actionType} setOpenModal3={setOpenModal3} />
      </Modal>
    </div>
  );
};

export default AboutBankCard;
