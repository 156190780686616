import { useNavigate } from "react-router-dom";
import prev from "../../assets/drawables/prev.svg";
import "./style.scss";
const BackToMarket = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate(-1);
      }}
      className="pd_back_to_market"
    >
      <img src={prev} alt="prev" />
      Back to Market
    </button>
  );
};

export default BackToMarket;
