import React, { useCallback } from "react";
import SelectField from "components/InputFields/SelectField";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { FormProvider, useForm } from "react-hook-form";
import identity from "../../../assets/drawables/identity.svg";

import mark from "../../../assets/drawables/mark.svg";
import ImageUpload from "components/InputFields/imageUpload";
import SupportBtn from "components/SupportBtn";
import { useNavigate } from "react-router-dom";
import BackBtn from "components/BackBtn";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadFile } from "../../../lib/api";
import { AppInfo, FileUploadIntent } from "../../../lib/api/requestTypes";
import { useAppInfo } from "../../../default/queries";
import { ComponentLoader } from "../../../components/ComponentLoader";
import { UserQueryKeys } from "../queries";

type IdentitySchema = {
	idType: string,
	front: FileList,
	back: FileList,
};

type IdentityInfoFormProps = {
	appInfo: AppInfo,
};
const IdentityInfoForm = ({ appInfo }: IdentityInfoFormProps) => {
	const methods = useForm<IdentitySchema>();
	const navigate = useNavigate();

	const uploadFileMutation = useMutation({ mutationFn: uploadFile });
	const queryClient = useQueryClient();

	const onSubmit = useCallback((values: IdentitySchema) => {
		const formData = new FormData();
		formData.set('intent', FileUploadIntent.IDENTITY);
		formData.set('file', values.front[0]);
		formData.set('metadata[type]', values.idType);
		formData.set('metadata[position]', 'front');

		const mutations = [uploadFileMutation.mutateAsync(formData)];

		formData.set('file', values.back[0]);
		formData.set('metadata[position]', 'back');
		mutations.push(uploadFileMutation.mutateAsync(formData));

		Promise.all(mutations).then(() => {
			queryClient.invalidateQueries({ queryKey: UserQueryKeys.profile });
			navigate('/dashboard');
		})
	}, [navigate, queryClient, uploadFileMutation]);

	const { identity: { options: idOptions } } = appInfo
	const { isPending } = uploadFileMutation;

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="formHead">
					<img src={identity} alt="profile" />
					<p>Proof of Identity</p>
				</div>

				<SelectField
					label="Select Type of ID card"
					name="idType"
					selectOption={idOptions}
					required
				/>
				<div className="command">
					<div className="eachCommand">
						<img src={mark} alt="mark" />
						<p>Ensure you picture is clear</p>
					</div>
					<div className="eachCommand">
						<img src={mark} alt="mark" />
						<p>Ensure to capture ID Number</p>
					</div>
				</div>

				<ImageUpload
					label='Upload Front Side of your ID card'
					subLabel='Front Side'
					formLabel='front'
				/>
				<ImageUpload
					label='Upload Back Side of your ID card'
					subLabel='Back Side'
					formLabel='back'
				/>

				<SubmitBtn disabled={isPending} isLoading={isPending} btnText='Submit' />
			</form>
		</FormProvider>
	);
};

export default function IdentityInfo() {
	const appInfoQuery = useAppInfo();
	const componentPropsFn = (appInfo: AppInfo) => ({ appInfo });

	return (
		<div className="pd_complete_registration">
			<BackBtn />
			<SupportBtn />
			<div className="center_container">
				<ComponentLoader
					component={IdentityInfoForm}
					queries={[appInfoQuery]}
					componentPropsFn={componentPropsFn}
				/>
			</div>
		</div>
	);
};
