import { useNavigate } from "react-router-dom";
import chip from "../../../../../assets/drawables/chip.svg";
import "./style.scss";
const EachBankCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate("card/1");
      }}
      className="pd_each_bank_card"
    >
      <div className="top">
        <img src={item.img} alt="card" />
      </div>
      <img src={chip} alt="chip" className="chip" />
      <div className="name_box">
        <p className="name">{item.name}</p>
        <p className="date">{item.date}</p>
      </div>
      <p className="number">{item.number}</p>
    </div>
  );
};

export default EachBankCard;
