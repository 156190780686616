import React from "react";
import "./style.scss";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

type HamburgerProps = {
  onClick: () => void,
}
const Hamburger = ({ onClick }: HamburgerProps) => {
  return (
    <div onClick={onClick} className="cover_ham">
      <IconButton aria-label="delete">
        <MenuIcon />
      </IconButton>
    </div>
  );
};

export default Hamburger;
