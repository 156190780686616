import { Box, FormControl, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { useWalletContext } from "./WalletProvider";
import { useState } from "react";
import { CardPayment } from "components/CardPayment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { topupWallet } from "lib/api";
import { useNavigate } from "react-router-dom";
import { UserQueryKeys } from "pages/Authentication/queries";
import { WalletQueryKeys } from "pages/Dashboard/BankICE/queries";

type InputAmountStepProps = {
	onSubmit: (amount: number) => void,
}
function InputAmountStep({ onSubmit }: InputAmountStepProps) {
	const { walletCurrency } = useWalletContext();
	const [amount, setAmount] = useState('');

	const amountIsValid = !Number.isNaN(Number(amount)) && Number(amount) > 0;

	return (
		<>
			<Typography color="primary">How much would you like to topup?</Typography>
			<FormControl fullWidth sx={{ mt: 3, mb: 1 }}>
				<OutlinedInput
					startAdornment={<InputAdornment position="start">{walletCurrency!.symbol}</InputAdornment>}
					type="number"
					name="amount"
					value={amount}
					onChange={(e) => setAmount(e.target.value)}
				/>
			</FormControl>
			<SubmitBtn
				btnText="Continue"
				disabled={!amountIsValid}
				onClick={() => amountIsValid && onSubmit(Number(amount))}
			/>
		</>
	);
}

type PaymentStepProps = {
	amount: number,
};
function PaymentStep({ amount }: PaymentStepProps) {
	const { wallet } = useWalletContext();
	const navigate = useNavigate();

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: () => topupWallet(wallet!.id, amount),
	});

	const onPayComplete = () => {
		queryClient.invalidateQueries({ queryKey: WalletQueryKeys.detail(wallet!.id) });
		queryClient.invalidateQueries({ queryKey: UserQueryKeys.profile });
		navigate(`/dashboard/bank/${wallet!.id}`);
	};

	return (
		<>
			<Typography mb={2} color="primary" variant="h6">Enter your card details</Typography>
			<CardPayment
				amount={amount}
				currency={wallet!.currency}
				mutation={mutation}
				paymentSuccessMsg="Your wallet has been topped up successfully"
				onPayComplete={onPayComplete}
			/>
		</>
	);
}

export function Topup() {
	const [step, setStep] = useState<'amount' | 'pay'>('amount');
	const [amount, setAmount] = useState<number>();

	const onAmountSubmit = (amount: number) => {
		setAmount(amount);
		setStep('pay');
	};

	return (
		<Box marginX="auto" marginTop={5} width="90%" maxWidth={400}>
			{step === 'amount' && <InputAmountStep onSubmit={onAmountSubmit} />}
			{step === 'pay' && <PaymentStep amount={amount!} />}
		</Box>
	);
}