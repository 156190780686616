import React from "react";
import { SendMoneyProvider, SendMoneyStep, useSendMoneyContext } from "./SendMoneyProvider";
import { AmountStep } from "./steps/Amount";
import { SelectRecipientStep } from "./steps/SelectRecipient";
import { SummaryStep } from "./steps/Summary";
import { PaymentMethodStep } from "./steps/PaymentMethod";
import { PayWithCard } from "./steps/PayWithCard";
import { PaywithWallet } from "./steps/PayWithWallet";

const STEP_COMPONENTS: Record<SendMoneyStep, () => React.JSX.Element> = {
	amount: AmountStep,
	recipient: SelectRecipientStep,
	summary: SummaryStep,
	payment: PaymentMethodStep,
	'payment/card': PayWithCard,
	'payment/wallet': PaywithWallet,
};

function ActiveStep() {
	const { step } = useSendMoneyContext();
	const StepComponent = STEP_COMPONENTS[step];

	return  <StepComponent />;
}

export function SendMoneyWizard() {

	return (
		<SendMoneyProvider>
			<ActiveStep />
		</SendMoneyProvider>
	);
}
