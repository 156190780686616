import React from "react";
import "./style.scss";
import { useState } from "react";
import cad from "../../../assets/drawables/england.svg";
import question from "../../../assets/drawables/question.svg";
import { Link } from "react-router-dom";
import EachTransaction from "components/EachTransaction";
import Modal from "components/ModalPopUp";
import TrackTransaction from "./components/TrackTransactionModal";
import Growth from "components/Growth";
import investment from "../../../assets/drawables/investment.svg";
// @ts-ignore
import Trend from "react-trend";
import girl from "../../../assets/drawables/girl.png";
import { NetWorth } from "./components/NetWorth";
import { Accounts } from "./components/Accounts";
import { Assets } from "./components/Assets";
import { WatchList } from "./components/WatchList";
import { SendMoneyBox } from "./components/SendMoneyBox";
import { useAppData, useProfileName } from "../../../default/AppDataProvider";

const Home = () => {
  const { appInfo } = useAppData();
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState("Top Risers");

  const [firstName] = useProfileName();

  const listStatus = ["Top Risers", "Top Fallers", "Volume Leaders"];

  const handleStatus = (type: string) => {
    setStatus(type);
  };

  const isTransactionEmpty = true;
  const promoEnabled = true;

  return (
    <div className="pd_home">
      <div className="home_flex">
        <div className="white_part">
          <p className="welcome">
            Hello, <span> {firstName}! ✌🏾</span>
          </p>
          <p className="sub_welcome">
            Welcome to the International Currency Exchange. This is an overview
            of your investments and activities
          </p>

          <NetWorth />

          <div className="pink_box">
            <div className="cover">
              <p>
                <span> $1,000 </span>
                free transfer units left
              </p>
              <img src={question} alt="question" />
            </div>
          </div>

          <Accounts />
          
          {promoEnabled && (
            <div className="promo_box">
              <img src={girl} alt="girl" />
              <p className="promo_text bold">
                Get <span>5%</span> of your first investment as free transfer.
              </p>
              <Link to="/dashboard/markets">Explore Markets</Link>
            </div>
          )}

          <Assets />

          <WatchList />

          <div className="tableSide">
            <p className="topic bold">Market Highlights</p>
            <p className="sub">
              Currencies with the biggest percentage increase in the last 24
              hours
            </p>

            <div className="btn_box">
              <div className="btn_cover">
                {listStatus.map((item) => {
                  return (
                    <button
                      key={item}
                      className={`filter_btn ${
                        item.toLocaleLowerCase() === status.toLocaleLowerCase()
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleStatus(item);
                      }}
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
              <Link to="/dashboard/markets">View Market</Link>
            </div>
            <div className="cover_table">
              <div className="over_table">
                <table>
                  <thead>
                    <tr>
                      <th>Assets</th>
                      <th>Yield(pa)</th>

                      <th className="center">Risk</th>
                      <th className="center">Trend</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3, 4, 5].map((item) => {
                      return (
                        <tr key={item}>
                          <td>
                            <div className="country">
                              <img src={cad} alt="flag" />
                              <div className="textSide">
                                <p className="top">USD</p>
                                <p className="bottom">
                                  Dollar Backed Securities
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <Growth content={"2.5"} />
                          </td>
                          <td>
                            <div className="risk">
                              <p>Low</p>
                              <div className="line"></div>
                            </div>
                          </td>
                          <td>
                            <div className="trend">
                              <Trend
                                data={[0, 10, 5, 22, 3.6, 11]}
                                autoDraw
                                autoDrawDuration={200}
                                autoDrawEasing="ease-in"
                                gradient={["#34E36F"]}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="dark_part">
          <div className="convBox">

            <SendMoneyBox currencies={appInfo.supportedCurrencies} />

            <div className="cover_transaction">
              <div className="title_flex">
                <p>Transaction History</p>
                <Link to={"/"}>View All</Link>
              </div>
              {isTransactionEmpty ? (
                <div className="empty_tran">
                  <img src={investment} alt="invest" />
                  <p className="no_transaction bold">No Transactions</p>
                  <p className="desc">You have no transaction history</p>
                </div>
              ) : (
                <>
                  {" "}
                  <p className="day">Today</p>
                  <EachTransaction
                    tranName={"Ademola"}
                    amount={"534.23 NGN"}
                    tranStat={"In progress"}
                    handleClick={() => {
                      setOpenModal(true);
                    }}
                  />
                  <EachTransaction
                    tranName={"Ademola"}
                    amount={"534.23 NGN"}
                    tranStat={"Succesful"}
                    handleClick={() => {
                      setOpenModal(true);
                    }}
                  />
                  <EachTransaction
                    tranName={"Ademola"}
                    amount={"534.23 NGN"}
                    tranStat={"Failed"}
                    handleClick={() => {
                      setOpenModal(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>

      </div>
      <Modal
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
        title={"Track Transfer"}
      >
        <TrackTransaction closeModal={() => setOpenModal(false)} />
      </Modal>
    </div>
  );
};

export default Home;
