import ListConversion from "components/ListConversion";
import { useState } from "react";
import "./style.scss";
import { ReferralLinkBox } from "./ReferralLinkBox";
import cn from "classnames";
import { ReferredUsers } from "./ReferredUsers";
import { Earnings, EarningsProps } from "./Earnings";
import { useReferralSummaryQuery } from "./queries";
import { ComponentLoader, ErrorState } from "components/ComponentLoader";
import { Centered } from "components/Centered";
import { CircularLoading } from "components/CircularLoading";
import { ReferralSummary } from "lib/api/apiTypes";


interface ReferralPageViewProps {
	referralSummary: ReferralSummary;
}
function ReferralPageView({ referralSummary }: ReferralPageViewProps) {
	const [activeTab, setActiveTab] = useState<EarningsProps['tab']>('overview');

	return (
		<div className="main_ref">
			<div className="filter_ice">
				<div
					onClick={() => setActiveTab('overview')}
					className={cn('eachfilter_ice', { active: activeTab === 'overview' })}
				>
					<p>Overview</p>
					<div className="act_line"></div>
				</div>
				<div
					onClick={() => setActiveTab('currency')}
					className={cn('eachfilter_ice', { active: activeTab === 'currency' })}
				>
					<p>By Currency</p>
					<div className="act_line"></div>
				</div>
			</div>
			<div className="cover_ref">
				<div className="left_ref">
					<Earnings tab={activeTab} />
					<ReferredUsers referees={referralSummary.referees} />
				</div>
				<ReferralLinkBox referralCode={referralSummary.referralCode} />
			</div>
		</div>
	);
}

export default function ReferralPage() {
	const summaryQuery = useReferralSummaryQuery();

	return (
		<div className="pd_ref_page">
			<ListConversion />

			<ComponentLoader
				queries={[summaryQuery]}
				component={ReferralPageView}
				componentPropsFn={(referralSummary) => ({ referralSummary })}
				renderLoading={() => (
					<Centered>
						<CircularLoading />
					</Centered>
				)}
				renderError={() => (
					<Centered>
						<ErrorState message="Something went wrong" />
					</Centered>
				)}
			/>
		</div>
	);
};
