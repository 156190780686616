import { useState } from "react";
import PickBank from "../../../Market/widget/PickBank/pickBank";
import SendMoneyHeader from "../../widget/SendMoneyHeader";
import "./style.scss";
import user from "assets/drawables/user.svg";
import userblue from "assets/drawables/userblue.svg";
import EachReceipientCard from "../../widget/EachRecipientCard/eachRecipientCard";
import ModalRight from "components/ModalRight";
import { RecipientForm } from "../../widget/RecipientForm";
import { useSendMoneyContext } from "../../SendMoneyProvider";
import { ID, TransferRecipient } from "lib/api/apiTypes";
import { ComponentLoader } from "components/ComponentLoader";
import { useTransferRecipientsQuery } from "pages/Dashboard/BankICE/queries";

const addRecipientDetails = {
  id: '',
  name: "Add a new Recipient",
  desc: "Send money to a new recipient. Their details will be saved for subsequent transactions.",
  img: user,
};

type RecipientListProps = {
  recipients: TransferRecipient[],
}
function RecipientList({ recipients }: RecipientListProps) {
  const { gotoNextStep, updateTransfer } = useSendMoneyContext();

  const onSelectRecipient = (id: ID) => {
    const recipient = recipients.find(item => item.id === id);
    updateTransfer({ recipient });
    gotoNextStep();
  }

  if (!recipients.length) {
    return (
      <div className="no_recipient_div">
        <img src={userblue} alt="user" />
        <p className="bold">No saved recipient</p>
      </div>
    );
  }

  return (
    <div className="saved_recipient_div">
      {recipients.map(item => (
        <EachReceipientCard key={item.id} item={item} onClick={onSelectRecipient} />
      ))}
    </div>
  );
}

export const SelectRecipientStep = () => {
  const [openModal, setOpenModal] = useState(false);

  const recipientsQuery = useTransferRecipientsQuery();
  const componentPropsFn = (recipients: TransferRecipient[]) => ({ recipients });

  return (
    <div className="pd_select_recipient">
      <SendMoneyHeader active="recipient" />
      <div className="main_aspect">
        <p className="first_text_send bold">Who are you sending money to?</p>
        <div className="cover_bank_list">
          <PickBank
            item={addRecipientDetails}
            onClick={() => setOpenModal(true)}
          />
        </div>

        <p className="choose">or choose an existing recipient</p>
        <ComponentLoader
          queries={[recipientsQuery]}
          component={RecipientList}
          componentPropsFn={componentPropsFn}
        />
      </div>
      <ModalRight
        title='Add a New Recipient'
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      >
        <div className="cover_rec_form">
          <p className="instruction">
            Please ensure information provided is current to avoid delay
          </p>
          <RecipientForm />
        </div>
      </ModalRight>
    </div>
  );
};

