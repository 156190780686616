import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import { useState } from "react";
import OtpInput from "react-otp-input";
import "./style.scss";
const EnterPin = ({ setDetail, setOpenModal }) => {
  const [otp, setOtp] = useState("");
  const handleChange = (otp) => {
    setOtp(otp);
  };
  return (
    <div className="pd_enter_pin_box">
      <div className="cover_all_otp">
        <div className="otp_wrap">
          <label>Please enter your card PIN to show the details</label>

          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            inputType="password"
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
      </div>
      <SubmitBtn
        type="button"
        disable={otp ? true : false}
        onClick={() => {
          setDetail(true);
          setOpenModal(false);
        }}
        className="btn-primary blue"
        btnText={"Show Details"}
      />
    </div>
  );
};

export default EnterPin;
