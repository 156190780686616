import React, { useCallback, useEffect } from "react";
import InputField from "components/InputFields";
import AuthTemplate from "components/Templates/authTemplate";
import { FormProvider, useForm } from "react-hook-form";
import "./style.scss";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import IconWidget from "components/IconWidget";
import google from "../../assets/drawables/google.svg";
import facebook from "../../assets/drawables/facebook.svg";
import apple from "../../assets/drawables/apple.svg";
import lock from "../../assets/drawables/lock.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InferType, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { hasAuth, loginUser } from "../../lib/api";
import { Alert } from "@mui/material";
import { UserQueryKeys } from "./queries";

const loginSchema = object({
  email: string().required(),
  password: string().required(),
}).required();

const Login = () => {
  const methods = useForm({
    resolver: yupResolver(loginSchema),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess(response) {
      queryClient.setQueryData(UserQueryKeys.profile, response.data.user);
      const route = location.state?.from ?? '/dashboard';
      navigate(route);
    },
  });

  const onSubmit = useCallback((data: InferType<typeof loginSchema>) => {
    loginMutation.mutate(data);
  }, [loginMutation]);

  // Redirect to dashboard if user is already logged in
  useEffect(() => {
    if (hasAuth()) {
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <div className="pd_signup">
      <AuthTemplate>
        <p className="header_text">Welcome Back</p>

        {loginMutation.isError && loginMutation.error instanceof Error && (
          <Alert severity="error">{loginMutation.error.message}</Alert>
        )}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField
              type="email"
              name="email"
              placeholder="stephen@gmail.com"
              id="email"
              label="Email Address"
            />

            <InputField
              type="password"
              name="password"
              id="password"
              label="Password"
            />

            <Link className="forgot" to="/signup">
              Forgot Password
            </Link>

            <SubmitBtn
              disabled={loginMutation.isPending}
              isLoading={loginMutation.isPending}
              icon={lock}
              btnText='Sign in'
            />
          </form>
          <p className="continue">Or Continue with</p>
          <div className="socials">
            <IconWidget>
              {" "}
              {/* <Google /> */}
              <img src={google} alt="" />
            </IconWidget>
            <IconWidget>
              {" "}
              {/* <Facebook /> */}
              <img src={facebook} alt="" />
            </IconWidget>
            <IconWidget>
              {" "}
              {/* <Apple />{" "} */}
              <img src={apple} alt="" />
            </IconWidget>
          </div>

          <p className="login_text">
            I don't have an account?
            <button
              onClick={() => {}}
              className="link_to"
            >
              {" "}
              Sign Up
            </button>{" "}
          </p>
        </FormProvider>
      </AuthTemplate>
    </div>
  );
};

export default Login;
