import NavbarDashboard from "../../../../components/NavbarDashboard";
import { stepperList } from "../constants";
import SupportBtn from "../../../../components/SupportBtn";
import BackBtn from "../../../../components/BackBtn";

type SendMoneyHeaderProps = {
  active: typeof stepperList[number],
}
const SendMoneyHeader = ({ active }: SendMoneyHeaderProps) => {
  const showBackBtn = active !== 'amount';

  return (
    <>
      <NavbarDashboard header="Send Money" stepper={{ list: stepperList, active }} />
      {showBackBtn && <BackBtn />}
      <SupportBtn className='down' />
    </>
  );
};

export default SendMoneyHeader;
